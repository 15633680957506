export const contactData = {
  brandName: "",
  phoneNumber1: "+91 735 999 0414",
  phoneNumber2: "+91 846 949 8001",
  phoneNumber3: "+91 940 936 5085",
  phoneNumber4: "+91 96016 87231",
  emailId: "info@dsiheritagespices.com",
  address:
    "SHOP NO. 28, MANMANDIR COMPLEX, OPP. BUS STATION, MAHUVA ROAD, SAVAR KUNDLA - 364515, GUJARAT, INDIA.",
  addressDangerHtml:
    "<p>SHOP NO. 28,<br/> MANMANDIR COMPLEX,<br/> OPP. BUS STATION,<br/> MAHUVA ROAD, SAVAR KUNDLA - 364515,<br/> GUJARAT, INDIA.</p>",
  facebookProfileLink: "https://www.facebook.com/dsiheritagespices/",
  instagramProfileLink: "https://www.instagram.com/dsiheritagespices/",
  linkedinProfileLink:
    "https://www.linkedin.com/in/dobariya-sons-international-heritage-spices-dsi-heritage-spices-a26aba302/",
  whatsappBusinessProfileLink: "https://wa.me/message/JFUQOIVMICXQH1",
  googleReviewLink: "https://g.page/r/CXMjB9owrW8IEAI/review",
};
