import React from "react";
import { AiOutlineFileSearch } from "react-icons/ai";

const ContactUs = () => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-6 my-1">
          <div
            className="card rounded-4 text-white py-3 px-3 my-1"
            style={{ background: "rgba(0, 0, 0, 0.29)" }}
          >
            <div className="fs-3 mb-3 text-center" style={{ fontWeight: 600 }}>
              <AiOutlineFileSearch className="me-2" />
              Get in Touch
            </div>
            <p className="text-center">
              Thanks for your interest! Please give us some more information
              about yourself and we'll be in touch soon.
            </p>
            <form className="contact-form">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <input
                    style={{
                      opacity: 0.8,
                      backgroundColor: "black",
                      color: "white",
                    }}
                    type="text"
                    placeholder="Organization Name"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  {" "}
                  <input
                    style={{
                      opacity: 0.8,
                      backgroundColor: "black",
                      color: "white",
                    }}
                    type="text"
                    placeholder="Name"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <input
                    style={{
                      opacity: 0.8,
                      backgroundColor: "black",
                      color: "white",
                    }}
                    type="text"
                    placeholder="Designation"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <input
                    style={{
                      opacity: 0.8,
                      backgroundColor: "black",
                      color: "white",
                    }}
                    type="text"
                    placeholder="Product Name"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  {" "}
                  <input
                    style={{
                      opacity: 0.8,
                      backgroundColor: "black",
                      color: "white",
                    }}
                    type="text"
                    placeholder="Estimated Quantity"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <input
                    style={{
                      opacity: 0.8,
                      backgroundColor: "black",
                      color: "white",
                    }}
                    required
                    type="email"
                    placeholder="Email id"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <input
                    style={{
                      opacity: 0.8,
                      backgroundColor: "black",
                      color: "white",
                    }}
                    type="text"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <textarea
                    style={{
                      opacity: 0.8,
                      backgroundColor: "black",
                      color: "white",
                    }}
                    placeholder="Address"
                  />
                </div>
              </div>

              <button>Send</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
