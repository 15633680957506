import React from 'react'
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { finalDehydratedData, finalMasalaData, finalSpiceData } from '../../data/FinalProductData';

const SpicesAndMasala = ({ screenWidth }) => {
    const navigate = useNavigate();
    return (
        <>
            {/* <div style={{ backgroundColor: '#96ff96' }}> */}
            <div style={{ backgroundColor: 'var(--primary-theme)' }}>
                <h2 className='text-center mb-2 pt-2 text-white'>{finalSpiceData.type}</h2>
                <p className='container text-white text-center'>Whole spices are spices that are used in their natural, intact form, as opposed to being ground into powder or processed into spice blends. These spices include items such as whole peppercorns, cinnamon sticks, cloves, cardamom pods, and cumin seeds, among others. They are valued for their strong and distinctive flavors, as well as their ability to retain their aroma and potency for longer periods compared to ground spices. Whole spices are often toasted, crushed, or ground as needed to release their flavors and are commonly used in various cuisines to add depth and complexity to dishes.</p>
                <SliderComponent navigate={navigate} productData={finalSpiceData} screenWidth={screenWidth} />
            </div>
            <div style={{ backgroundColor: 'var(--primary-theme)' }}>
                {/* <div style={{ backgroundColor: '#c4ffc4' }}> */}
                <h2 className='text-center mb-2 pt-2 text-white'>{finalMasalaData.type}</h2>
                <p className='container text-white text-center'>Ground spices are spices that have been processed into a fine powder form by grinding or milling the raw spices. This process breaks down the spices into smaller particles, increasing their surface area and releasing their flavors and aromas. Ground spices are commonly used in cooking and baking to season and flavor dishes. They include popular spices such as ground cinnamon, ground ginger, ground cumin, and ground paprika, among others. Ground spices are convenient to use and readily mix into recipes, providing consistent flavor throughout the dish.</p>
                <SliderComponent navigate={navigate} productData={finalMasalaData} screenWidth={screenWidth} />
            </div>
            <div style={{ backgroundColor: 'var(--primary-theme)' }}>
                <h2 className='text-center mb-2 pt-2 text-white'>{finalDehydratedData.type}</h2>
                <p className='container text-white text-center'>Dehydrated flakes or powder refer to food items, typically fruits, vegetables, or herbs, that have had their moisture content removed through a drying process. This process involves removing the water content from the food, either through air drying, sun drying, or using specialized equipment such as dehydrators. Dehydrated flakes or powder are often used as convenient ingredients in cooking and baking, as they have a longer shelf life compared to fresh produce and are easy to store and transport. They can be rehydrated by soaking in water or added directly to recipes to impart flavor, texture, and nutritional benefits.</p>
                <SliderComponent navigate={navigate} productData={finalDehydratedData} screenWidth={screenWidth} />
            </div>
        </>
    );
}

export const SliderComponent = ({ navigate, productData, rest, screenWidth }) => {
    const settings = {
        dots: true,
        autoplay: screenWidth < 768 ? true : productData.items.length < 4 ? false : true,
        infinite: screenWidth < 768 ? true : productData.items.length < 4 ? false : true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        cssEase: 'linear',
        pauseOnHover: false,
        arrows: false,
        speed: 1000,
        ...rest,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                },
            },
        ],
    }
    console.log("checksettings", settings)
    return (<div className='slider-container'>
        <Slider
            {...settings}
        >
            {productData.items.map((item, index) => {
                return (
                    <ProductCard
                        item={{ ...item, productName: "Product Name" }}
                        navigate={navigate}
                        index={index}
                    />
                );
            })}
        </Slider>
    </div>)
}

const ProductCard = ({ item, navigate, index }) => {
    return (
        <div
            className="card rounded-1 my-1 mx-auto product-card slide-product"
            onClick={() => {
                navigate(`${window.location.pathname}/${item.name}`, {
                    state: {
                        productName: item.name
                    }
                });
            }}
        >
            <img
                src={item.image}
                className="card-img-top"
                alt="..."
                loading="lazy"
                style={{
                    height: 200,
                    width: 200,
                    borderRadius: "50%",
                    margin: "10px auto 0",
                }}
            />
            <div className="card-body">
                <h5 className="card-title text-center">{item.name}</h5>
                <p className="card-text" style={{ lineHeight: "22px" }}>
                    {item.desc}
                </p>
            </div>
        </div>
    )
}

export default SpicesAndMasala