import React from "react";

import HomeBanner1 from "../assets/final_asset/homeBanner/HomeBanner1.jpg"
import HomeBanner2 from "../assets/final_asset/homeBanner/HomeBanner2.png"
import HomeBanner3 from "../assets/final_asset/homeBanner/HomeBanner3.png"

import { WhyChooseData } from "../data/WhyChooseData";

import { AiOutlineFileSearch } from "react-icons/ai";
import { welcomeContent } from "../data/welcomeContent";
import { ourProductData } from "../data/ProductsData";
import ContactUs from "./ContactUs";
import { useNavigate } from "react-router-dom";
import Certificatiions from "./Certificatiions";
import MemberSection from "./MemberSection";

const HomePage = ({ contactUsRef, screenWidth }) => {
  let navigate = useNavigate();
  return (
    <>
      {/*================ Carousal/Banner ================*/}
      <div
        id="carouselExample"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="2000">
            <img
              className="icon-banner-1 bannerLogo"
              src={HomeBanner1}
              alt="banner"
              loading="lazy"
            />
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <img
              className="icon-banner-1 bannerLogo"
              src={HomeBanner2}
              alt="banner"
              loading="lazy"
            />
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <img
              className="icon-banner-1 bannerLogo"
              src={HomeBanner3}
              alt="banner"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      {/* ================Welcome Content================ */}
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: welcomeContent }} />
      </div>
      {/* ================Our Products Section================ */}
      <div style={{ backgroundColor: "whitesmoke" }}>
        <div className="text-bold py-2 text-center section-title">
          Our Products
        </div>
        {/* <div className='text-bold py-2 text-center fs-2' style={{ color: 'var(--primary-theme)', fontWeight: 700 }}>Rice Supplier in India</div> */}
      </div>
      {/*================ Products================ */}
      <div
        className="container-fluid mb-4 pb-2"
        style={{ backgroundColor: "whitesmoke" }}
      >
        <div className="container">
          <div className="row py-1 justify-content-center">
            {ourProductData.map((item) => {
              return (
                <div className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center my-3">
                  <Card item={item} navigate={navigate} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/*================ Welcome to global trade================ */}
      {/* <div className='container-fluid'>
                <div className='container py-5'>
                    <ImageDesc
                        title={'Welcome To Global Trade'}
                        image={CuminSeed}
                        desc={`<p>We are fortunate to have the option to offer Indian Exporter of the best nature of heartbeats, rice, and flavors to advertise on the Global market. Ajy Global Trade gives the opportunity in contrast to the conventional import-send-out organizations as a broker product import business.</p>

                        <p>As a Basmati Rice Suppliers in India, we work with worldwide countries like Israel, UAE, UK, Netherlands, USA, Australia, Malaysia, Singapore, Saudi Arabia, Qatar, Oman, and the Inlet. Every activity is managed by a more young, dynamic, and experienced gathering, more established individuals who are specialists in market examination, client control, and tracking down their records.</p>
                        `}
                    />
                </div>
            </div> */}
      {/* ================Why choose us section ================*/}
      <div
        className="container-fluid pb-2"
        style={{ backgroundColor: "var(--primary-theme)", color: "white" }}
      >
        <div
          className="container"
        // style={{ backgroundColor: "var(--primary-theme)", color: "white" }}
        >
          <div
            className="text-center fs-1 pt-3 section-title mb-4"
            style={{ color: "white" }}
          >
            Why Choose us
          </div>
          <p className="text-center fs-5">
            At DSI Heritage Spices, we take pride in being your trusted source
            for the finest Indian spices.
          </p>
          <p className="text-center fs-5">
            Here's why you should choose DSI Heritage Spices for all your spice
            needs
          </p>
          <div className="row py-2">
            {WhyChooseData.map((item) => {
              return (
                <div className="col-sm-12 col-md-6 col-lg-4 my-2 d-flex justify-content-center">
                  <WhyChooseCard item={item} />
                </div>
              );
            })}
          </div>
          <p className="text-center fs-5 mt-3">
            Choose DSI Heritage Spices for unparalleled quality, variety, and
            service. Experience the difference that heritage and tradition make
            in every bite.
          </p>
          <p className="text-center fs-5 mb-4">
            Choose Heritage Spices for the best quality, variety, and service in
            the world of Indian spices.
          </p>
        </div>
      </div>
      {/*================ Certificates ================*/}
      <div id="certification">
        <Certificatiions />
      </div>
      {/*================ Member of ================*/}
      <div style={{ backgroundColor: 'var(--primary-theme)', color: 'white' }} className="mt-3" id="memberSection">
        <MemberSection />
      </div>
      {/*================ Popular variety ================*/}
      {/* <div className='container'>
                <div className='text-center fs-1 pt-5'>Popular Varieties Of Rice Manufacturers In India</div>
                <div className='row py-1'>
                    {PopularVarietyData.map((item) => {
                        return (<div className='col-sm-12 col-md-6 col-lg-4'>
                            <PopularVarietyCard item={item} />
                        </div>)

                    })}
                </div>
            </div> */}
      {/* ================Popular indian space================ */}
      {/* <div className='container-fluid'>
                <div className='container py-5'>
                    <ImageDesc
                        title={'Popular INDIAN SPICE EXPORTS'}
                        image={CuminSeed}
                        desc={`<p>Spices are an essential component of many Indian recipes. Indian spices are well-known worldwide for their distinct flavor, scent, and gorgeous texture. At AJY Global Trade, the Indian Spices Exporter, we think that Indian culture and our unique spices in India should be available to people all over the world.</p>
                        
                        <p>AJY Global Trade is one of the most prominent export companies for Indian spices. We export a variety of spices, including turmeric, ginger, star anise, cinnamon, asafoetida, cumin, black pepper, and many more.</p>
                        
                        <p>AJY Global Trade, a well-regarded Spices Exporters in Mumbai, has extensive experience in the food business and can find the best clients for your products. We have global contacts as one of the most prominent exporters of Indian Masala.</p>
                        
                        <p>You can export Indian spices to any continent through us. We think that the consumer is King and that our method is transparent and dependable. The headquarters are in the center of Pune, and we have trained executives who analyze the market to provide you with the most up-to-date information. If you are seeking Spice Exporters in India, we can help!</p>
                        `}
                    />
                </div>
            </div> */}
      {/*================ Why best section ================*/}
      {/* <div className='container'>
                <div className='row'>
                    {WhyBestData.map((item) => {
                        return (
                            <div className='col-sm-12 col-md-6 col-lg-4'>
                                <WhyBestCard item={item} />
                            </div>
                        )
                    })}
                </div>
            </div> */}
      {/* ================Customer focus section================ */}
      {/* <div className='container-fluid'>
                <div className='container py-5'>
                    <ImageDesc
                        title={'A Customer-Focused Organic'}
                        subTitle={`PESTICIDE-FREE RICE EXPORTER!`}
                        image={CuminSeed}
                        desc={`<p>Understanding what the consumer wants and needs is critical for leading Rice Manufacturers in India to produce goods of unrivaled quality and value. By harvesting nature’s finest produce and packing only the best specialty grains and consumables for families all over the world, we have established ourselves as one of the greatest Rice Manufacturers India.</p>

                        <p>From farm to fork, our fully integrated operations have access to and control over the entire supply chain. To satisfy the individual needs of the customer, we can offer rice packaging in sizes ranging from 1 kilogram to 50 kg. These can be further customized in any size and packing material as needed by the client.</p>
                        
                        <p>At AJY Global Trade, the <b>Rice Exporter In India</b>, We also specialize in bulk rice appropriate for catering companies. As packaging materials, PP Bags, Non-Woven Bags, Jute Bags, and a few other types of bags are utilized for product delivery.</p>
                        `}
                        isImageLeft={false}
                        isReadMoreButton={false}
                    />
                </div>
            </div> */}
      {/* ================largest exprots================ */}
      {/* <div className='container-fluid'>
                <div className='container py-5'>
                    <ImageDesc
                        title={'Largest'}
                        subTitle={`EXPORTER OF PULSES`}
                        image={CuminSeed}
                        desc={`<p>India, ranked as the world’s third-largest <b>Exporter of Pulses</b>, is renowned for delivering top-quality products globally. High demand for Indian goods exists in countries like the USA, UK, UAE, Nepal, and more. India exports various pulses, including chickpeas, kidney beans, pigeon peas, and black-eyed peas, meeting the demands of discerning nations.</p>

                        <p>AJY Global Trade, India’s <b>Best Spice &amp; Rice Exporters in Mumbai</b>, prioritizes the security and food safety standards required for imports. Our commitment to clients ensures that all goods reach their destination in optimal condition within the specified timeframe. We take pride in safeguarding product quality and meeting delivery deadlines to uphold our reputation as a reliable trading partner.</p>
                        `}
                        isReadMoreButton={false}
                    />
                </div>
            </div> */}
      {/* ================Core Values section================ */}
      {/* <div className='container'>
                <div className='text-center fs-2' style={{ fontWeight: 700 }}>CORE VALUE</div>
                <div className='text-center'>AJY Global Trade is extremely enthusiastic about its work. We aspire to provide greater value to our clients, shareholders, and staff. A shared set of values and goals shared by all members of our firm acts as a standard reference point and enables us all to effectively navigate the future.</div>
                <div className='row'>
                    {CoreValueData.map((item) => {
                        return (
                            <div className='col-sm-12 col-md-6'>
                                <WhyBestCard item={item} />
                            </div>
                        )
                    })}
                </div>
            </div> */}
      {/* ================4 company logo================ */}
      {/* <div
        className="container-fluid py-5 px-4"
        style={{ backgroundColor: "#dedede" }}
      >
        <h3 className="text-center section-title">We are available on</h3>
        <div className="container">
          <Slider
            dots
            infinite
            speed={800}
            slidesToShow={screenWidth < 768 ? 2 : 4}
            slidesToScroll={1}
          >
            <div className="d-flex justify-content-center p-3">
              <img
                src={ArtBoard1}
                style={{ width: screenWidth < 768 ? "150px" : "180px" }}
                loading="lazy"
              />
            </div>
            <div className="d-flex justify-content-center p-3">
              <img
                src={ArtBoard2}
                style={{ width: screenWidth < 768 ? "150px" : "180px" }}
                loading="lazy"
              />
            </div>
            <div className="d-flex justify-content-center p-3">
              <img
                src={ArtBoard3}
                style={{ width: screenWidth < 768 ? "150px" : "180px" }}
                loading="lazy"
              />
            </div>
            <div className="d-flex justify-content-center p-3">
              <img
                src={ArtBoard4}
                style={{ width: screenWidth < 768 ? "150px" : "180px" }}
                loading="lazy"
              />
            </div>
          </Slider>
        </div> 
      </div>*/}
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3716.5385384680094!2d71.30960560943258!3d21.329242980314543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be265389f9f22c3%3A0x86fad30da072373!2sDOBARIYA%20SONS%20INTERNATIONAL%20HERITAGE%20SPICES!5e0!3m2!1sen!2sin!4v1713378907885!5m2!1sen!2sin"
          width="800"
          height="600"
          style={{ border: 0 }}
          className="map-box"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      {/* ================About us and Contact us section================ */}
      {/* <div
        className="container-fluid my-4 contact-us-section py-1"
        id="contact-us"
        ref={contactUsRef}
      >
        <div className="container"><AboutAndContact /></div>
      </div> */}
      <div
        className="container-fluid contact-us-section"
        id="contact-us"
        ref={contactUsRef}
      >
        <div className="container">
          <ContactUs />
        </div>
      </div>
    </>
  );
};

export const Card = ({ item, navigate }) => {
  return (
    <div
      className="card rounded-1 my-1 product-card"
      style={{ maxWidth: "350px", boxShadow: "0px 0px 20px 0px #989898" }}
      onClick={() => {
        navigate(item.route);
      }}
    >
      <img
        src={item.image}
        className="card-img-top"
        alt="..."
        loading="lazy"
        style={{
          height: 200,
          width: 200,
          borderRadius: "50%",
          margin: "10px auto 0",
        }}
      />
      <div className="card-body">
        <h5 className="card-title text-center">{item.productName}</h5>
        <p className="card-text" style={{ lineHeight: "22px" }}>
          {item.desc.length > 200 ? item.desc.slice(0, 200) + "...." : item.desc}
        </p>
      </div>
    </div>
  );
};

const ImageDesc = ({
  title,
  subTitle,
  image,
  desc,
  isImageLeft = true,
  isReadMoreButton = true,
}) => {
  return (
    <div className="row">
      {isImageLeft ? (
        <>
          <div className="col-sm-12 col-md-6">
            <img
              src={image}
              alt="..."
              style={{ width: "100%" }}
              loading="lazy"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="fs-2" style={{ color: "var(--primary-theme)" }}>
              {title}
            </div>
            <div className="fs-2">{subTitle}</div>
            <div dangerouslySetInnerHTML={{ __html: desc }} />
            {isReadMoreButton && (
              <button className="read-more-button">Read More</button>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="col-sm-12 col-md-6">
            <div className="fs-2">{title}</div>
            <div className="fs-2">{subTitle}</div>
            <div dangerouslySetInnerHTML={{ __html: desc }} />
            {isReadMoreButton && (
              <button className="read-more-button">Read More</button>
            )}
          </div>
          <div className="col-sm-12 col-md-6">
            <img
              src={image}
              alt="..."
              style={{ width: "100%" }}
              loading="lazy"
            />
          </div>
        </>
      )}
    </div>
  );
};

const WhyChooseCard = ({ item }) => {
  return (
    <div
      className="card my-1 h-100"
      style={{
        border: "none",
        boxShadow: "0px 0px 20px 0px #989898 inset",
        borderRadius: "36px 0",
        maxWidth: 350,
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <img
          src={item.image}
          className="card-img-top"
          alt="..."
          style={{
            maxWidth: "300px",
            margin: "26px auto",
            borderRadius: "8% 0",
            width: "85%",
          }}
          loading="lazy"
        />
      </div>
      <div
        className="card-body"
        style={{
          // borderLeft: "2px solid var(--primary-theme)",
          padding: "0 15px",
          marginTop: 10,
        }}
      >
        <h5 className="card-title text-center">{item.title}</h5>
        <p className="card-text text-center" style={{ lineHeight: "20px" }}>
          {item.desc}
        </p>
      </div>
    </div>
  );
};

const PopularVarietyCard = ({ item }) => {
  return (
    <div
      className="card my-1 p-2"
      style={{ boxShadow: "0px 0px 10px -2px rgba(0,0,0,0.5)" }}
    >
      <img
        src={item.image}
        className="card-img-top"
        alt="..."
        style={{ width: "100%" }}
        loading="lazy"
      />
      <div className="card-body">
        <h5 className="card-title text-center">{item.title}</h5>
        <p className="card-text text-center">{item.desc}</p>
      </div>
      <button className="read-more-button my-0 mx-auto">Read More</button>
    </div>
  );
};

const WhyBestCard = ({ item }) => {
  return (
    <div
      style={{ backgroundColor: "#f7f7f7", margin: "10px 0" }}
      className="p-3"
    >
      <div
        className="text-center fs-4 my-3"
        style={{ color: "var(--primary-theme)", fontWeight: "bold" }}
      >
        {item.title}
      </div>
      <div>{item.desc}</div>
    </div>
  );
};

const AboutAndContact = () => {
  return (
    <>
      <h2 className="text-white">Contact Us</h2>
      <div className="mb-2 text-white">
        We are proud to announce our business enterprise AJY Global Trade is an
        Indian Basmati Rice Suppliers in India, installed in 2021, currently
        Company in Himachal Pradesh (India). We enjoy making difficult duties
        viable in sourcing commercial enterprise, which has used a storm to take
        conventional Import Export corporations.
      </div>
      <div className="row">
        {/* <div className="col-sm-12 col-md-8 my-1">
          <div
            className="card rounded-4 text-white py-3 px-3 h-100 my-1"
            style={{ background: "rgba(0, 0, 0, 0.29)" }}
          >
            <div className="fs-4 font-weight-bold mb-3">Corporate Office</div>
            <div
              dangerouslySetInnerHTML={{
                __html: contactData.addressDangerHtml,
              }}
            />
            <div>
              <div className="d-flex">
                <div style={{ width: "60px", fontWeight: 700 }}>Email</div>
                <div>{contactData.emailId}</div>
              </div>
              <div className="d-flex my-2">
                <div className="social-icons me-3">
                  <FaFacebookF
                    color="black"
                    size={25}
                    onClick={() => {
                      redirectToPage("Facebook");
                    }}
                  />
                </div>
                <div className="social-icons me-3">
                  <CiYoutube
                    color="black"
                    size={25}
                    onClick={() => {
                      redirectToPage("");
                    }}
                  />
                </div>
                <div className="social-icons me-3">
                  <FaTwitter
                    color="black"
                    size={25}
                    onClick={() => {
                      redirectToPage("");
                    }}
                  />
                </div>
                <div className="social-icons me-3">
                  <FaInstagram
                    color="black"
                    size={25}
                    onClick={() => {
                      redirectToPage("Instagram");
                    }}
                  />
                </div>
                <div className="social-icons me-3">
                  <FaLinkedin
                    color="black"
                    size={25}
                    onClick={() => {
                      redirectToPage("Linkedin");
                    }}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div style={{ width: "60px", fontWeight: 700 }}>Join us</div>
                <div>{contactData.emailId}</div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-sm-12 col-md-4 my-1">
          <div
            className="card rounded-4 text-white py-3 px-3 my-1"
            style={{ background: "rgba(0, 0, 0, 0.29)" }}
          >
            <div className="fs-4 font-weight-bold mb-3 text-center">
              <AiOutlineFileSearch className="me-2" />
              Get in Touch
            </div>
            <p>
              Thanks for your interest! Please give us some more information
              about yourself and we'll be in touch soon.
            </p>
            <form className="contact-form">
              <input
                style={{
                  opacity: 0.8,
                  backgroundColor: "black",
                  color: "white",
                }}
                type="text"
                placeholder="Full Name"
              />
              <input
                style={{
                  opacity: 0.8,
                  backgroundColor: "black",
                  color: "white",
                }}
                required
                type="email"
                placeholder="Email"
              />
              <input
                style={{
                  opacity: 0.8,
                  backgroundColor: "black",
                  color: "white",
                }}
                type="text"
                placeholder="Phone Number"
              />
              <textarea
                style={{
                  opacity: 0.8,
                  backgroundColor: "black",
                  color: "white",
                }}
                placeholder="Message"
              />
              <button>Send</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
