import React from 'react'
import { finalFruitsAndVegetablesData } from '../../data/FinalProductData';
import { SliderComponent } from './SpicesAndMasala';
import { useNavigate } from 'react-router-dom';

const FruitsAndVegetables = () => {
    const navigate = useNavigate();

    console.log("finalFruitsAndVegetablesData", finalFruitsAndVegetablesData)
    return (
        <>
            <div style={{ backgroundColor: 'var(--primary-theme)' }}>
                <h2 className='text-center mb-2 pt-2 text-white'>{finalFruitsAndVegetablesData.type}</h2>
                <p className='container text-white text-center'>Fresh fruits and vegetables refer to produce that is harvested and consumed in its natural state, without undergoing significant processing or preservation methods. These are fruits and vegetables that have not been canned, frozen, dried, or otherwise preserved for an extended period. They are typically sold in their raw, natural form and are valued for their nutritional content, flavor, and freshness. Eating fresh fruits and vegetables is often encouraged for their abundance of vitamins, minerals, fiber, and antioxidants, which are beneficial for overall health and well-being.</p>
                <SliderComponent navigate={navigate} productData={finalFruitsAndVegetablesData} />
            </div>
        </>
    )
}

export default FruitsAndVegetables