import React from "react";
import MainLogo from "../assets/main-logo.png";
import { Link, useNavigate } from "react-router-dom";

import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { IoMdMail } from "react-icons/io";
import { contactData } from "../data/ContactData";
import { GiHamburgerMenu } from "react-icons/gi";
import { redirectToPage } from "../utils";
import { ourProductData } from "../data/ProductsData";

const Header = ({ isSticky, screenWidth, scrollToBottom }) => {
  const navigate = useNavigate();
  const handleActiveMenu = (getRouteName) => {
    if (window.location.pathname === getRouteName) {
      return "active-menu active";
    }
    return "";
  };

  const handleActiveProductDropdownMenu = () => {
    if (
      window.location.pathname === "/products/cumin-seeds" ||
      window.location.pathname === "/products/turmeric-powder" ||
      window.location.pathname === "/products/chilli-powder" ||
      window.location.pathname === "/products/coriender-powder"
    ) {
      return "active-menu-item";
    }
    return "";
  };

  const handleDropdownMenu = () => {
    let dropdownMenu = document.getElementById("web-dropdown-menu");
    dropdownMenu.classList.remove("show");
  };

  const handleScrollToMemberSection = () => {
    navigate("/")
    setTimeout(() => {
      document.getElementById("memberSection")?.scrollIntoView();
    }, 150);
  }

  const handleScrollToCertificationSection = () => {
    navigate("/")
    setTimeout(() => {
      document.getElementById("certification")?.scrollIntoView();
    }, 150);
  }

  return (
    <>
      <div
        className="container-fluid px-0 position-sticky top-0"
        style={{
          zIndex: 1,
          backgroundColor: "#353535",
          color: "white",
        }}
      >
        <div
          className="container d-flex justify-content-between main-header"
          style={{
            zIndex: 1,
            background: screenWidth < 768 ? "#353535" : "transparent",
          }}
        >
          <div className="main-header-content-alignment d-flex main-header-hide-content align-items-center justify-content-center gap-3">
            <div className="fs-5" style={{ fontWeight: 600 }}>
              <IoMdMail className="me-2" size={25} />
              {contactData.emailId}
            </div>
            <div className="fs-5" style={{ fontWeight: 600 }}>
              <IoIosCall className="me-2" size={25} />
              {contactData.phoneNumber4}
            </div>
          </div>
          <div className="main-header-content text-center">
            <Link className="navbar-brand d-block d-md-none" to={"/"}>
              <img
                src={MainLogo}
                alt="logo"
                style={{ borderRadius: "unset" }}
                loading="lazy"
              />
            </Link>
          </div>
          <div className="hamburger-menu">
            <GiHamburgerMenu
              size={30}
              style={{ display: "flex", alignItems: "center" }}
              className="toggle-btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasTop"
              aria-controls="offcanvasTop"
            />
          </div>
          <div className="main-header-hide-content d-flex justify-content-start align-items-center">
            <FaFacebookF
              size={25}
              color="#316FF6"
              className="mx-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                redirectToPage("Facebook");
              }}
            />
            <FaInstagram
              size={28}
              color="white"
              className="mx-3 instaLogo"
              style={{ cursor: "pointer" }}
              onClick={() => {
                redirectToPage("Instagram");
              }}
            />
            <FaLinkedin
              size={28}
              color="#0077B5"
              className="mx-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                redirectToPage("Linkedin");
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="container-fluid d-none d-md-block"
        style={{
          background: "var(--primary-theme2)",
          padding: "10px 0",
          zIndex: 1,
          position: isSticky ? "sticky" : "",
          top: 0,
        }}
      >
        <div className="container d-flex justify-content-between main-header-hide-content">
          <Link className="navbar-brand" to={"/"}>
            <img
              src={MainLogo}
              alt="logo"
              style={{
                borderRadius: "unset",
                width: isSticky ? "70px" : "200px",
              }}
              loading="lazy"
            />
          </Link>
          <div className="d-flex justify-content-evenly align-items-center">
            <div className="nav-item menu-items">
              <Link
                className={`nav-link main-nav-menu-item ${handleActiveMenu(
                  "/"
                )}`}
                aria-current="page"
                to="/"
              >
                Home
              </Link>
            </div>
            <div className="nav-item menu-items dropdown">
              <Link
                className={`nav-link ${handleActiveProductDropdownMenu()} dropdown-toggle main-nav-menu-item`}
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Our Products
              </Link>
              <ul className="dropdown-menu" id="web-dropdown-menu">
                {ourProductData.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        navigate(item.route);
                      }}
                    >
                      <Link
                        className={`dropdown-item ${handleActiveMenu(
                          item.route
                        )}`}
                        to={item.route}
                      >
                        {item.productName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="nav-item menu-items main-nav-menu-item">
              <Link
                className={`nav-link ${handleActiveMenu("/")}`}
                aria-current="page"
                to="/"
                onClick={() => { handleScrollToMemberSection() }}
              >
                We are Member of
              </Link>
            </div>
            <div className="nav-item menu-items main-nav-menu-item">
              <Link
                className={`nav-link ${handleActiveMenu("/certifications")}`}
                aria-current="page"
                to="/"
                onClick={() => { handleScrollToCertificationSection() }}
              >
                Certificate
              </Link>
            </div>
            <div className="nav-item menu-items main-nav-menu-item">
              <Link
                className="nav-link active"
                aria-current="page"
                to="/"
                onClick={() =>
                  setTimeout(() => {
                    scrollToBottom()
                  }, 500)
                }
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-top"
        tabIndex="-1"
        id="offcanvasTop"
        aria-labelledby="offcanvasTopLabel"
        style={{ height: "55%", backgroundColor: "#353535", color: "#fff" }}
      >
        <div className="offcanvas-header">
          <Link className="navbar-brand" to={"/"}>
            <img
              src={MainLogo}
              alt="logo"
              style={{ width: "80px", borderRadius: "unset" }}
              loading="lazy"
            />
          </Link>
          <button
            type="button"
            className="btn-close text-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body pt-0">
          <div
            className="nav-item my-1"
            style={{ borderBottom: "1px solid lightgray" }}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Link
              className={`nav-link ${handleActiveMenu("/")}`}
              style={{ borderBottom: "1px solid lightgray" }}
              aria-current="page"
              to="/"
            >
              Home
            </Link>
          </div>
          <div
            className="nav-item dropdown my-1"
            style={{ borderBottom: "1px solid lightgray" }}
          >
            <Link
              className={`nav-link dropdown-toggle ${handleActiveProductDropdownMenu()}`}
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Our Products
            </Link>
            <ul className="dropdown-menu">
              {ourProductData.map((item) => {
                return <li data-bs-dismiss="offcanvas" aria-label="Close">
                  <Link className="dropdown-item" to={item.route}>
                    {item.productName}
                  </Link>
                </li>
              })}
            </ul>
          </div>
          <div
            className="nav-item my-1"
            style={{ borderBottom: "1px solid lightgray" }}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Link
              className={`nav-link ${handleActiveMenu("/")}`}
              aria-current="page"
              to="/"
              onClick={() => { handleScrollToMemberSection() }}
            >
              We are Member of
            </Link>
          </div>
          <div
            className="nav-item my-1"
            style={{ borderBottom: "1px solid lightgray" }}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Link
              className={`nav-link ${handleActiveMenu("/certifications")}`}
              aria-current="page"
              to="/"
              onClick={() => { handleScrollToCertificationSection() }}
            >
              Certificate
            </Link>
          </div>
          <div
            className="nav-item my-1"
            style={{ borderBottom: "1px solid lightgray" }}
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Link
              className="nav-link active"
              aria-current="page"
              to="/"
              onClick={() =>
                // contactUsRef.current.scrollIntoView({
                //   behavior: "smooth",
                //   block: "start",
                // })
                setTimeout(() => {
                  scrollToBottom()
                }, 500)
              }
            >
              Contact Us
            </Link>
          </div>
          <div className="">
            <div>
              <IoMdMail className="me-2" />
              {contactData.emailId}
            </div>
            <div>
              <IoIosCall className="me-2" />
              {contactData.phoneNumber4}
            </div>
          </div>
          <div className="">
            <FaFacebookF
              size={25}
              color="#316FF6"
              className="mx-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                redirectToPage("Facebook");
              }}
            />
            <FaInstagram
              size={28}
              color="white"
              className="mx-3 instaLogo"
              style={{ cursor: "pointer" }}
              onClick={() => {
                redirectToPage("Instagram");
              }}
            />
            <FaLinkedin
              size={28}
              color="#0077B5"
              className="mx-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                redirectToPage("Linkedin");
              }}
            />
          </div>
        </div>
      </div >
    </>
  );
};

export default Header;
