import React from "react";
import { contactData } from "../data/ContactData";

import MainLogo from "../assets/main-logo.png";
import GoogleLogo from "../assets/google-logo.png";

import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { redirectToPage } from "../utils";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <div
      className="container-fluid footer1 d-flex align-items-center"
      style={{ minHeight: 280, color: "#fff", fontWeight: 600 }}
    >
      <div className="container">
        <div className="row">
          <div
            className="col-sm-12 col-md-6 py-3"
          // style={{ background: "black" }}
          >
            <div className="fs-5 mb-4 text-center">
              <img
                src={MainLogo}
                alt="main-logo"
                style={{ width: "120px" }}
                loading="lazy"
              />
            </div>
            {/* <div className="mb-3 fs-4">
              Dobariya Sons International Heritage Spices
            </div> */}
            {/* <div className="mb-3 fs-4">Brand: DSI Heritage Spices</div> */}
            <div>
              <div className="">
                {/* <div style={{ minWidth: "65px" }}>Address:</div> */}
                <div
                  style={{ textDecoration: "capitalize" }}
                  className="text-center text-md-start"
                >
                  {contactData.address}
                </div>
                {/* <div
                  style={{ textDecoration: "capitalize" }}
                  className="text-center text-md-start"
                >
                  At DSI HERITAGE SPICES, we specialize in exporting a wide
                  range of high-quality products to meet the diverse needs of
                  our customers worldwide. From aromatic spices and flavourful
                  masalas to nutritious pulses and wholesome flours, Fresh
                  Fruits and Vegetables and Additive Food Products, we have
                  everything you need to spice up your culinary creations and
                  nourish your body.
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 py-3">
            <h4>Contact us</h4>
            <div className="mb-1 mb-md-3 fs-5">{contactData.emailId}</div>
            <div className="mb-1 mb-md-3 fs-5">{contactData.phoneNumber4}</div>
            {/* <div className="mb-3 fs-5">{contactData.phoneNumber2}</div> */}
            {/* <div className="mb-3 fs-5">{contactData.phoneNumber3}</div> */}
          </div>
          <div className="col-sm-12 col-md-3 py-3">
            <h4>Social Media</h4>
            <div className="d-flex d-md-block">
              <div className="my-3 d-flex align-items-center me-4">
                <FaInstagram
                  color="white"
                  className="instaLogo"
                  style={{ cursor: "pointer" }}
                  size={30}
                  onClick={() => {
                    redirectToPage("Instagram");
                  }}
                />
                <h5 className="ms-2 mb-0 d-none d-md-block">Instagram</h5>
              </div>
              <div className="my-3 d-flex align-items-center me-4">
                <span className="fbLogo">
                  <FaFacebookF
                    color="#fff"
                    size={24}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      redirectToPage("Facebook");
                    }}
                  />
                </span>
                <h5 className="ms-2 mb-0 d-none d-md-block">Facebook</h5>
              </div>
              <div className="my-3 d-flex align-items-center me-4">
                <FaLinkedin
                  color="#0077B5"
                  size={30}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    redirectToPage("Linkedin");
                  }}
                />
                <h5 className="ms-2 mb-0 d-none d-md-block">Linkedin</h5>
              </div>
              <div className="my-3 d-flex align-items-center me-4">
                <img
                  src={GoogleLogo}
                  alt="google-logo"
                  style={{ width: 26, height: 26 }}
                  onClick={() => {
                    redirectToPage("GoogleReview");
                  }}
                />
                {/* <FaLinkedin
                  color="#0077B5"
                  size={30}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    redirectToPage("Linkedin");
                  }}
                /> */}
                <h5 className="ms-2 mb-0 d-none d-md-block">Google Reviews</h5>
              </div>
            </div>
            {/* <div>
              <FaTwitter
                color="white"
                size={20}
                onClick={() => {
                  redirectToPage("");
                }}
              />
            </div> */}
            {/* <div>
              <CiYoutube
                color="white"
                size={20}
                onClick={() => {
                  redirectToPage("");
                }}
              />
            </div> */}
            <div className="whatsappLogo">
              <FaWhatsapp
                color="#fff"
                size={28}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  redirectToPage("Whatsapp");
                }}
              />
            </div>
          </div>
        </div>
        {/* <div
          className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center"
          style={{
            fontSize: "18px",
            fontWeight: 600,
            minHeight: 42,
          }}
        >
          <div>Dobariya Sons International Heritage Spices</div>
          <div>
            <strong>Brand:&nbsp;</strong>DSI Heritage Spices
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
