import { contactData } from "./data/ContactData"

export const redirectToPage = (getData) => {
    if (getData === "Facebook") {
        window.open(contactData.facebookProfileLink, "_blank")
    } else if (getData === "Instagram") {
        window.open(contactData.instagramProfileLink, "_blank")
    } else if (getData === "Linkedin") {
        window.open(contactData.linkedinProfileLink, "_blank")
    } else if (getData === "Whatsapp") {
        window.open(contactData.whatsappBusinessProfileLink, "_blank")
    } else {
        window.open(contactData.googleReviewLink, "_blank")
    }
}