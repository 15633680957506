import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { productData } from "../data/ProductsData";

import SpecImage from "../assets/spec-image.jpg";

const ContentPage = ({ pageContent }) => {
  let { productName } = useParams();
  let navigate = useNavigate();
  let currentProduct;
  let currentPageContent;
  if (productName === undefined) {
    currentPageContent = pageContent;
  } else {
    let content = productData.filter(
      (item) =>
        item.productName.toLocaleLowerCase().replaceAll(" ", "-") ===
        productName
    );
    currentProduct = content;
    currentPageContent = content[0]?.productData;
  }

  return (
    <>
      <div className="container">
        {currentPageContent !== undefined ? (
          <div dangerouslySetInnerHTML={{ __html: currentPageContent }} />
        ) : (
          <div className="text-center my-3 fs-4">No Data Found!</div>
        )}
        <div className="row justify-content-around">
          <Specifications
            currentProduct={currentProduct?.[0]}
            productName={productName}
            navigate={navigate}
          />
        </div>
      </div>
    </>
  );
};

const Specifications = ({ currentProduct, productName, navigate }) => {
  const handleClickSpecificationButton = (getItemsId) => {
    navigate(`/products/${productName}/${getItemsId}`);
  };

  return currentProduct?.specificationData?.map((item, index) => {
    return (
      <>
        <div className="col-sm-12 col-md-6 col-lg-4 my-2">
          <div className="card h-100" style={{ border: "1px solid #353535" }}>
            <div className="product-spec my-3">
              <div className="product-image">
                <img src={SpecImage} alt="product-img" loading="lazy" />
              </div>
              <div className="mx-3">
                <h5>{item.Title}</h5>
                <div>
                  <b>Quality: </b>
                  {item.details.Quality.join(", ")}
                </div>
                {productName === "turmeric-powder" ? (
                  <div>
                    <b>Crop of Indian State: </b>
                    {item.details["Crop of Indian State"].join(", ")}
                  </div>
                ) : (
                  <>
                    <div>
                      <b>Purity: </b>
                      {item.details.Purity.join(", ")}
                    </div>
                    <div>
                      <b>Process: </b>
                      {item.details.Process.join(", ")}
                    </div>
                  </>
                )}
                <button
                  className="spec-btn w-100"
                  onClick={() => {
                    handleClickSpecificationButton(index);
                  }}
                >
                  SPECIFICATIONS
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  });
};

export default ContentPage;
