export const privacyPolicyData = `
<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

<p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.</p>

<h2>Information Collection and Use</h2>
<p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

<h3>Types of Data Collected</h3>
<ul>
  <li>Personal Data: While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
    <ul>
      <li>Email address</li>
      <li>First name and last name</li>
      <li>Phone number</li>
      <li>Address, State, Province, ZIP/Postal code, City</li>
      <li>Cookies and Usage Data</li>
    </ul>
  </li>
</ul>

<h3>Use of Data</h3>
<p>DSI Heritage Spices uses the collected data for various purposes:</p>
<ul>
  <li>To provide and maintain the Service</li>
  <li>To notify you about changes to our Service</li>
  <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
  <li>To provide customer care and support</li>
  <li>To provide analysis or valuable information so that we can improve the Service</li>
  <li>To monitor the usage of the Service</li>
  <li>To detect, prevent and address technical issues</li>
</ul>

<h3>Transfer of Data</h3>
<p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there.
Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

<h3>Disclosure of Data</h3>
<p>Legal Requirements
DSI Heritage Spices may disclose your Personal Data in the good faith belief that such action is necessary to:
<ul>
  <li>To comply with a legal obligation</li>
  <li>To protect and defend the rights or property of DSI Heritage Spices</li>
  <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
  <li>To protect the personal safety of users of the Service or the public</li>
  <li>To protect against legal liability</li>
</ul>

<h3>Security of Data</h3>
<p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is</p>
`