import React from "react";

import Cert_Fssai from "../assets/final_asset/certificates/FSSAI_Logo.png"
import Cert_apeda from "../assets/final_asset/certificates/APEDA.png"
import Cert_dgft from "../assets/final_asset/certificates/DGFT - IEC CODE.jpg"
import Cert_msme from "../assets/final_asset/certificates/MSME Registered.png"
import Cert_spice_board from "../assets/final_asset/certificates/Spice Board.jpg"

const Certificatiions = () => {
  const data = [
    {
      id: 1,
      name: "FSSAI",
      image: Cert_Fssai
    },
    {
      id: 2,
      name: "APEDA",
      image: Cert_apeda
    },
    {
      id: 3,
      name: "MSME",
      image: Cert_msme
    },
    {
      id: 4,
      name: "IEC",
      image: Cert_dgft
    },
    {
      id: 5,
      name: "Spice Board",
      image: Cert_spice_board
    },
  ]
  return (
    <div className="container-fluid px-0">
      <div className="fs-2 text-center section-title">Our Certifications</div>
      <div className="container">
        <div className="row justify-content-evenly">
          {data.map((item) => {
            return (
              <div className="col-sm-12 col-md-4 text-center my-3 d-flex justify-content-center">
                <div className="card cert-card">
                  <img
                    src={item.image}
                    alt="..."
                    loading="lazy"
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default Certificatiions;
