import React from 'react'
import { useNavigate } from 'react-router-dom';
import { finalProcessedFoodProductData } from '../../data/FinalProductData';
import { SliderComponent } from './SpicesAndMasala';

const ProcessedFoodsProduct = () => {
    const navigate = useNavigate();
    return (
        <>
            <div style={{ backgroundColor: 'var(--primary-theme)' }}>
                <h2 className='text-center mb-2 pt-2 text-white'>{finalProcessedFoodProductData.type}</h2>
                <p className='container text-white text-center'>
                    Processed food products are food items that have undergone various methods of preparation, preservation, or alteration before being consumed. These methods can include cooking, canning, freezing, drying, fermenting, or adding preservatives, flavorings, or other additives. Processed food products can range from minimally processed items like washed and chopped vegetables to highly processed foods such as ready-to-eat meals, snacks, and packaged goods like canned soups, frozen dinners, and snack bars. While processing can sometimes enhance convenience, shelf life, and taste, it may also result in changes to the nutritional content of the food, such as the addition of salt, sugar, or unhealthy fats.
                </p>
                <SliderComponent navigate={navigate} productData={finalProcessedFoodProductData} />
            </div>
        </>
    )
}

export default ProcessedFoodsProduct