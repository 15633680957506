import React from "react";
import { Card } from "./HomePage";
import { useNavigate, useParams } from "react-router-dom";
import { ourProductData } from "../data/ProductsData";
import Slider from "react-slick";

import { finalSpiceData, finalMasalaData, finalDehydratedData, finalPulsesData, finalFlourAndAttaData, finalFruitsAndVegetablesData, finalProcessedFoodProductData } from "../data/FinalProductData";

const FinalData = [...finalSpiceData.items, ...finalMasalaData.items, ...finalDehydratedData.items, ...finalPulsesData.items, ...finalFlourAndAttaData.items, ...finalFruitsAndVegetablesData.items, ...finalProcessedFoodProductData.items];

const Products = () => {
  let { itemNumber } = useParams();
  const finalProd = FinalData.find((item) => item.name === itemNumber);
  console.log(finalProd)
  return (
    <div className="container-fluid py-3" style={{ backgroundColor: '#dbffec' }}>
      <div className="container">
        <div className="">
          <img src={finalProd.image} className="product-desc-image" alt="" />
          <ProdDesc item={finalProd} />
        </div>
      </div>
    </div>
  );
};

export const ProdDesc = ({ item }) => {
  if (item.name === "Cardamom") {
    return (
      item.items.map((cardItem, index) => {
        return (<><div>
          {Object.keys(cardItem).map((data) => {
            return (
              <>
                <h5 className="text-capitalize m-0">{data.replaceAll("_", " ")}</h5>
                <p>{cardItem[data]}</p>
              </>
            )
          })}
        </div>{index === 0 && <hr />}</>)
      })
    )
  } else {
    return (
      <div>
        {Object.keys(item).map((data) => {
          if (data !== "image") {
            return (
              <>
                <h5 className="text-capitalize m-0">{data.replaceAll("_", " ")}</h5>
                <p>{item[data]}</p>
              </>
            )
          }
        })}
      </div>
    )
  }
}

export default Products;
