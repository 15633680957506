import QAImage from "../assets/1. Quality Assurance.jpg";
import WideImage from "../assets/2. Wide Variety Products.jpg";
import GlobalImage from "../assets/3. Global Reach.jpg";
import AuthenticityImage from "../assets/4. Premium Natural Products.jpg";
import IntegrityImage from "../assets/5. Integrity & Business Ethics.jpg";
import CustomerImage from "../assets/6. Customer Satisfaction & Services.jpg";

export const WhyChooseData = [
  {
    id: 1,
    title: "Quality Assurance",
    image: QAImage,
    desc: "At DSI Heritage Spices, quality is our hallmark. We adhere to strict quality control measures at every stage of production, ensuring that only the finest ingredients make their way into our products.",
  },
  {
    id: 2,
    title: "Wide Variety",
    image: WideImage,
    desc: "With an extensive range of spices, pulses, fruits, vegetables, and additive food products, DSI Heritage Spices offers something for every palate and culinary need. Explore our diverse selection and discover new flavors to elevate your dishes.",
  },
  {
    id: 3,
    title: "Global Reach",
    image: GlobalImage,
    desc: `Our products reach customers around the globe, thanks to our robust distribution network and efficient logistics operations. Whether you're in Asia, Europe, Africa, or the Americas, you can enjoy the goodness of DSI Heritage Spices wherever you are.`,
  },
  {
    id: 4,
    title: "Authenticity Guaranteed",
    image: AuthenticityImage,
    desc: `When you choose DSI Heritage Spices, you can trust that you're getting authentic, high-quality products. We source our ingredients from trusted suppliers and follow traditional recipes to ensure the authenticity and purity of our offerings.`,
  },
  {
    id: 5,
    title: "Integrity",
    image: IntegrityImage,
    desc: `Honesty, transparency, and ethical business practices are the cornerstones of our company. We believe in doing business with integrity, treating our customers, suppliers, and employees with fairness and respect.`,
  },
  {
    id: 6,
    title: "Customer Satisfaction",
    image: CustomerImage,
    desc: "Your satisfaction is our ultimate goal. We go above and beyond to meet and exceed your expectations, providing personalized service, timely delivery, and top-notch products that delight your taste buds and enhance your culinary creations.",
  },
];
