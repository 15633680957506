import React from 'react'
import { useNavigate } from 'react-router-dom';
import { finalFlourAndAttaData } from '../../data/FinalProductData';
import { SliderComponent } from './SpicesAndMasala';

const FlourAndAtta = () => {
    const navigate = useNavigate();
    return (
        <>
            <div style={{ backgroundColor: 'var(--primary-theme)' }}>
                <h2 className='text-center mb-2 pt-2 text-white'>{finalFlourAndAttaData.type}</h2>
                <p className='container text-white text-center'>Flour, also known as atta (especially in South Asia), is a powder made by grinding raw grains, roots, beans, nuts, or seeds. It is a fundamental ingredient in many foods and is used primarily in baking and cooking. Flour is versatile and can be used to make various food items such as bread, cakes, pastries, noodles, and thickening agents for sauces and soups. It provides structure, texture, and flavor to a wide range of dishes, making it an essential staple in cuisines around the world.</p>
                <SliderComponent navigate={navigate} productData={finalFlourAndAttaData} />
            </div>
        </>
    )
}

export default FlourAndAtta