import CuminSeed from "../assets/final_asset/Cumin Seeds.jpg"
import CorianderSeed from "../assets/final_asset/coriander seeds 2.jpg"
import CinnamonStick from "../assets/final_asset/Cinnamon.jpg"
import FennelSeed from "../assets/final_asset/Fennel seeds 1.jpg"
import BlackPepper from "../assets/final_asset/Black Pepper.jpg"
import Cardamom from "../assets/final_asset/Cardamom (Small & Large).jpg"
import Clove from "../assets/final_asset/Clove 2.jpg"
import TurmericPowder from "../assets/final_asset/Turmeric Powder.jpg"
import ChilliPowder from "../assets/final_asset/Chilli Powder 1.jpg"
import CorianderPowder from "../assets/final_asset/Coriander Powder 1.jpg"
import ChickpeaWhite from "../assets/final_asset/Chickpea White (Kabuli Chana).jpg"
import ChickpeaBrown from "../assets/final_asset/Chickpea Brown (Brown Chana).jpg"
import MungBean from "../assets/final_asset/Mung bean (Green Moong).jpg"
import YellowPigeonPeas from "../assets/final_asset/Yellow Pigeon Peas (Tur Dal).jpg"
import GreenPeas from "../assets/final_asset/Green Peas.jpg"
import BlackGrams from "../assets/final_asset/Black Grams.jpg"
import UradDal from "../assets/final_asset/Urad dal 1.jpg"
import YellowMoongDal from "../assets/final_asset/Yellow Moong dal.jpg"
import Soybeans from "../assets/final_asset/Soybeans.jpg"
import WheatFlour from "../assets/final_asset/Wheat Flour 1.jpg"
import GramFlour from "../assets/final_asset/Gram Flour (Besan).jpg"
import CornFlour from "../assets/final_asset/Corn Flour 1.jpg"
import SoyaFlour from "../assets/final_asset/Soya Flour.jpg"
import BreadFlour from "../assets/final_asset/bread Flour.jpg"
import KuttuFlour from "../assets/final_asset/Kuttu Flour.jpg"
import JauKaFlour from "../assets/final_asset/Jau ka Flour (Barley flour).jpg"
import FreshMangoes from "../assets/final_asset/Fresh Mangoes 1.jpg"
import FreshPotato from "../assets/final_asset/Fresh Potatoes.jpg"
import MangoPulp from "../assets/final_asset/mango pulp.webp"
import FruitPulp from "../assets/final_asset/FRUIT PULPS.jpeg"
import DehydratedGarlicFlakes from "../assets/final_asset/De-hydrated Garlic Flakes.jpg"
import DehydratedGarlicPowder from "../assets/final_asset/De-hydrated Garlic Powder 1.jpg"
import DehydratedOnionFlakes from "../assets/final_asset/De-hydrated Onion Flakes.jpg"
import DehydratedOnionPowder from "../assets/final_asset/De-hydrated Onion Powder 2.jpg"
import DehydratedGreenPaper from "../assets/final_asset/De-hydrated Green Pepper.jpg"


export const finalSpiceData = {
    "type": "Whole Spices",
    "routeName": 'spices-and-masala',
    "items": [
        {
            "name": "Cumin Seeds",
            "image": CuminSeed,
            "description": "Cumin seeds are small, elongated seeds with a distinctive warm, earthy flavor and aroma.",
            "grades_quality": "Cumin seeds are graded based on factors such as size, color, and oil content. Higher quality seeds are larger, darker, and have a higher oil content.",
            "varieties": "Varieties include regular cumin seeds and organic cumin seeds, which are grown without synthetic pesticides or fertilizers.",
            "health_benefits": "Cumin seeds are rich in antioxidants and may aid in digestion, improve immune function, and reduce inflammation. They are also believed to have antimicrobial properties.",
            "storage_tips_shelf_life": "Store cumin seeds in an airtight container in a cool, dark place. Properly stored, they can last for up to one to two years."
        },
        {
            "name": "Coriander Seeds",
            "image": CorianderSeed,
            "description": "Coriander seeds are round, tan-colored seeds with a citrusy, slightly sweet flavor.",
            "grades_quality": "Coriander seeds are graded based on factors such as size, color, and aroma. Higher quality seeds are larger, lighter in color, and have a strong aroma.",
            "varieties": "Varieties include regular coriander seeds and organic coriander seeds, which are grown without synthetic additives or pesticides.",
            "health_benefits": "Coriander seeds are rich in antioxidants and may aid in digestion, reduce bloating, and lower blood sugar levels. They are also believed to have anti-inflammatory properties.",
            "storage_tips_shelf_life": "Store coriander seeds in an airtight container away from heat and moisture. Properly stored, they can last for up to three to four years."
        },
        {
            "name": "Cinnamon Sticks",
            "image": CinnamonStick,
            "description": "Cinnamon is a spice derived from the inner bark of trees belonging to the Cinnamomum genus. It has a sweet, warm flavor and aroma.",
            "grades_quality": "Cinnamon is graded based on factors such as flavor, aroma, and oil content. Higher quality cinnamon has a stronger flavor and aroma.",
            "varieties": "Varieties include Ceylon cinnamon, also known as 'true' cinnamon, and cassia cinnamon, which is more common and has a stronger flavor.",
            "health_benefits": "Cinnamon is rich in antioxidants and may help lower blood sugar levels, reduce inflammation, and improve heart health. It is also believed to have antimicrobial properties.",
            "storage_tips_shelf_life": "Store cinnamon sticks or powder in an airtight container away from heat and light. Properly stored, they can last for up to two to four years."
        },
        {
            "name": "Fennel Seeds",
            "image": FennelSeed,
            "description": "Fennel seeds are small, oval-shaped seeds with a sweet, licorice-like flavor.",
            "grades_quality": "Fennel seeds are graded based on factors such as size, color, and aroma. Higher quality seeds are larger, lighter in color, and have a strong aroma.",
            "varieties": "Varieties include regular fennel seeds and organic fennel seeds, which are grown without synthetic pesticides or chemicals.",
            "health_benefits": "Fennel seeds are rich in fiber, vitamins, and minerals. They may aid in digestion, reduce bloating, and freshen breath. Fennel seeds are also believed to have antioxidant and anti-inflammatory properties.",
            "storage_tips_shelf_life": "Store fennel seeds in an airtight container away from heat and moisture. Properly stored, they can last for up to three to four years."
        },
        {
            "name": "Black Pepper",
            "image": BlackPepper,
            "description": "Black pepper is derived from dried, unripe peppercorns and has a pungent, spicy flavor.",
            "grades_quality": "Black pepper is graded based on factors like size, aroma, and flavor. Higher quality pepper has a strong aroma and bold flavor.",
            "varieties": "Varieties include whole black peppercorns and ground black pepper. Whole peppercorns retain their flavor longer than pre-ground pepper.",
            "health_benefits": "Black pepper is rich in antioxidants and may aid in digestion, improve nutrient absorption, and promote gut health. It's also believed to have anti-inflammatory properties.",
            "storage_tips_shelf_life": "Store whole black peppercorns in an airtight container away from heat and light, lasting for up to three to four years. Ground black pepper should be stored in a similar manner, lasting for up to one to two years."
        },
        {
            "name": "Cardamom",
            "image": Cardamom,
            "items": [
                {
                    "type": "Small Cardamom",
                    "description": "Small cardamom, also known as green cardamom or true cardamom, is a spice made from the dried seeds of the Elettaria cardamomum plant. It has a strong, aromatic flavor with hints of citrus and mint.",
                    "grades_quality": "Small cardamom is graded based on factors such as size, color, aroma, and flavor intensity. Higher quality cardamom will have bright green pods, plump seeds, and a strong, fragrant aroma.",
                    "varieties": "Varieties of small cardamom include regular green cardamom and organic green cardamom, which is grown without synthetic pesticides or fertilizers.",
                    "health_benefits": "Small cardamom is rich in antioxidants, vitamins, and minerals such as potassium, calcium, and magnesium. It has been used in traditional medicine to aid digestion, freshen breath, relieve nausea, and improve respiratory health.",
                    "storage_tips_shelf_life": "Store small cardamom pods in an airtight container in a cool, dry place away from sunlight. Properly stored, they can retain their flavor and aroma for up to one year."
                },
                {
                    "type": "Large Cardamom",
                    "description": "Large cardamom, also known as black cardamom or Nepal cardamom, is a spice made from the dried seeds of the Amomum subulatum plant. It has a smoky, earthy flavor with notes of resin and camphor.",
                    "grades_quality": "Large cardamom is graded based on factors such as size, color, aroma, and flavor intensity. Higher quality cardamom will have large, dark brown pods, plump seeds, and a strong, aromatic aroma.",
                    "varieties": "Varieties of large cardamom include regular black cardamom and smoked black cardamom, which is dried over open flames to impart a smoky flavor.",
                    "health_benefits": "Large cardamom contains antioxidants, volatile oils, and other compounds that may have anti-inflammatory, antimicrobial, and digestive properties. It has been used in traditional medicine to relieve stomach issues, respiratory problems, and oral health issues.",
                    "storage_tips_shelf_life": "Store large cardamom pods in an airtight container in a cool, dry place away from sunlight. Properly stored, they can retain their flavor and aroma for up to one year."
                }
            ]
        },
        {
            "name": "Clove",
            "image": Clove,
            "description": "Cloves are small, dark brown flower buds with a strong, warm, and slightly sweet flavor.",
            "grades_quality": "Cloves are graded based on factors like size, color, and oil content. Higher quality cloves are plump, dark brown, and possess a high oil content.",
            "varieties": "Varieties include whole cloves and ground cloves. Whole cloves are often used in cooking and baking, while ground cloves are convenient for seasoning.",
            "health_benefits": "Cloves are rich in antioxidants and may aid in digestion, reduce inflammation, and alleviate toothaches. They are also believed to have antimicrobial properties.",
            "storage_tips_shelf_life": "Store whole cloves in an airtight container away from heat and moisture, lasting for up to one to two years. Ground cloves should be stored similarly, lasting for up to six months to one year."
        }
    ]
}


export const finalMasalaData = {
    "type": "Grinded/Blended Powder",
    "routeName": 'spices-and-masala',
    "items": [
        {
            "name": "Turmeric Powder",
            "image": TurmericPowder,
            "description": "Turmeric powder is derived from the root of the Curcuma longa plant, which is part of the ginger family. It is known for its bright yellow color and earthy flavor.",
            "grades_quality": "Turmeric powder is graded based on its color, aroma, and curcumin content. The higher the curcumin content, the better the quality. Grades range from standard to premium.",
            "varieties": "Varieties include regular turmeric powder and organic turmeric powder, with the latter being free from synthetic pesticides and fertilizers.",
            "health_benefits": "Turmeric powder contains curcumin, a compound with powerful anti-inflammatory and antioxidant effects. It may aid in digestion, boost immunity, and support joint health.",
            "storage_tips_shelf_life": "Store turmeric powder in an airtight container in a cool, dark place to maintain its flavor and potency. Properly stored, it can last up to two years."
        },
        {
            "name": "Chilli Powder",
            "image": ChilliPowder,
            "description": "Chilli powder is made from dried, ground chili peppers and is known for its fiery flavor and vibrant red color.",
            "grades_quality": "Chilli powder quality depends on the type of chili peppers used and the grinding process. It can range from mild to extra hot.",
            "varieties": "Varieties include regular chili powder, which may contain other spices like cumin and oregano, and pure chili powders made from specific chili pepper varieties.",
            "health_benefits": "Chilli powder is rich in capsaicin, a compound that has been linked to various health benefits, including pain relief, improved metabolism, and heart health.",
            "storage_tips_shelf_life": "Keep chili powder in a tightly sealed container away from heat and light. It can last for about one to two years if stored properly."
        },
        {
            "name": "Coriander Powder",
            "image": CorianderPowder,
            "description": "Coriander powder is made from ground coriander seeds and has a mild, citrusy flavor.",
            "grades_quality": "Coriander powder quality depends on the freshness and quality of the seeds used. It should have a fragrant aroma and a fine texture.",
            "varieties": "Varieties include regular coriander powder and organic coriander powder, which is made from coriander seeds grown without synthetic pesticides or fertilizers.",
            "health_benefits": "Coriander powder contains antioxidants, vitamins, and minerals. It may aid in digestion, reduce bloating, and help regulate blood sugar levels.",
            "storage_tips_shelf_life": "Store coriander powder in an airtight container in a cool, dry place away from sunlight. Properly stored, it can last up to one year."
        }
    ]
}


export const finalDehydratedData = {
    "type": "Dehydrated (Flakes/Powder)",
    "routeName": 'spices-and-masala',
    "items": [
        {
            "name": "Dehydrated Garlic Flakes",
            "image": DehydratedGarlicFlakes,
            "description": "Dehydrated garlic flakes are made by drying fresh garlic cloves and then crushing them into small, dry flakes. They have a concentrated garlic flavor and aroma.",
            "grades_quality": "The quality of dehydrated garlic flakes depends on factors such as freshness, color, and aroma. Higher quality flakes will have a strong garlic aroma and retain their natural color.",
            "varieties": "Varieties include regular dehydrated garlic flakes and organic dehydrated garlic flakes, which are made from organically grown garlic without synthetic additives or pesticides.",
            "health_benefits": "Dehydrated garlic flakes retain many of the health benefits of fresh garlic, including its antibacterial, antiviral, and anti-inflammatory properties. They may also support heart health and boost the immune system.",
            "storage_tips_shelf_life": "Store dehydrated garlic flakes in an airtight container in a cool, dry place away from sunlight. Properly stored, they can last for up to one to two years."
        },
        {
            "name": "Dehydrated Garlic Powder",
            "image": DehydratedGarlicPowder,
            "description": "Dehydrated garlic powder is made by grinding dehydrated garlic flakes into a fine powder. It has a strong, concentrated garlic flavor and aroma.",
            "grades_quality": "Quality of dehydrated garlic powder is determined by factors such as texture, color, and aroma. Higher quality powder will be fine, light in color, and have a strong garlic aroma.",
            "varieties": "Varieties include regular dehydrated garlic powder and roasted dehydrated garlic powder, which has a more intense flavor due to the roasting process.",
            "health_benefits": "Dehydrated garlic powder offers similar health benefits to fresh garlic, including its ability to support immune function, promote heart health, and combat infections.",
            "storage_tips_shelf_life": "Store dehydrated garlic powder in an airtight container in a cool, dark place away from moisture. Properly stored, it can last for up to one to two years."
        },
        {
            "name": "Dehydrated Onion Flakes",
            "image": DehydratedOnionFlakes,
            "description": "Dehydrated onion flakes are made by drying fresh onions and then chopping them into small, dry pieces. They have a concentrated onion flavor and aroma.",
            "grades_quality": "Quality of dehydrated onion flakes is determined by factors such as size, color, and aroma. Higher quality flakes will be uniform in size, light in color, and have a strong onion aroma.",
            "varieties": "Varieties include regular dehydrated onion flakes and toasted dehydrated onion flakes, which have a slightly caramelized flavor due to the toasting process.",
            "health_benefits": "Dehydrated onion flakes retain many of the health benefits of fresh onions, including their antioxidant and anti-inflammatory properties. They may also support digestive health and boost immunity.",
            "storage_tips_shelf_life": "Store dehydrated onion flakes in an airtight container in a cool, dry place away from sunlight. Properly stored, they can last for up to one to two years."
        },
        {
            "name": "Dehydrated Onion Powder",
            "image": DehydratedOnionPowder,
            "description": "Dehydrated onion powder is made by grinding dehydrated onion flakes into a fine powder. It has a strong, concentrated onion flavor and aroma.",
            "grades_quality": "Quality of dehydrated onion powder depends on factors such as texture, color, and aroma. Higher quality powder will be fine, light in color, and have a strong onion aroma.",
            "varieties": "Varieties include regular dehydrated onion powder and toasted dehydrated onion powder, which has a slightly caramelized flavor due to the toasting process.",
            "health_benefits": "Dehydrated onion powder offers similar health benefits to fresh onions, including their ability to support heart health, regulate blood sugar levels, and improve digestion.",
            "storage_tips_shelf_life": "Store dehydrated onion powder in an airtight container in a cool, dark place away from moisture. Properly stored, it can last for up to one to two years."
        },
        {
            "name": "Dehydrated Green Pepper",
            "image": DehydratedGreenPaper,
            "description": "Dehydrated green pepper is made by drying fresh green bell peppers and then chopping them into small, dry pieces. They have a concentrated green pepper flavor and aroma.",
            "grades_quality": "Quality of dehydrated green pepper depends on factors such as size, color, and aroma. Higher quality pieces will be uniform in size, bright green in color, and have a strong pepper aroma.",
            "varieties": "Varieties include regular dehydrated green pepper and smoked dehydrated green pepper, which has a smoky flavor due to the smoking process.",
            "health_benefits": "Dehydrated green pepper retains many of the health benefits of fresh green peppers, including their high vitamin C content, antioxidant properties, and ability to support eye health.",
            "storage_tips_shelf_life": "Store dehydrated green pepper in an airtight container in a cool, dry place away from sunlight. Properly stored, it can last for up to one to two years."
        }
    ]
};


export const finalPulsesData = {
    "type": "Pulses",
    "items": [
        {
            "name": "Chickpea White (Kabuli Chana)",
            "image": ChickpeaWhite,
            "description": "Kabuli Chana, also known as white chickpeas, are large, round legumes with a creamy color and a nutty flavor.",
            "grades_quality": "Kabuli Chana is graded based on factors such as size, color, and uniformity. Higher quality chickpeas will be larger, uniform in size, and free from blemishes.",
            "varieties": "Varieties include regular Kabuli Chana and organic Kabuli Chana, which are grown without synthetic pesticides or fertilizers.",
            "health_benefits": "Kabuli Chana is rich in protein, fiber, vitamins, and minerals. It may aid in weight management, support heart health, and regulate blood sugar levels.",
            "storage_tips_shelf_life": "Store Kabuli Chana in an airtight container in a cool, dry place. Properly stored, they can last for up to one to two years."
        },
        {
            "name": "Chickpea Brown (Brown Chana)",
            "image": ChickpeaBrown,
            "description": "Brown Chana, also known as brown chickpeas or kala chana, are smaller and darker in color compared to Kabuli Chana. They have a slightly nuttier flavor and firmer texture.",
            "grades_quality": "Brown Chana is graded similarly to Kabuli Chana, based on factors like size, color, and uniformity.",
            "varieties": "Varieties include regular Brown Chana and organic Brown Chana, which are grown without synthetic additives or pesticides.",
            "health_benefits": "Brown Chana is rich in protein, fiber, and essential nutrients. It may aid in weight management, improve digestion, and regulate blood sugar levels.",
            "storage_tips_shelf_life": "Store Brown Chana in an airtight container in a cool, dry place. Properly stored, they can last for up to one to two years."
        },
        {
            "name": "Mung Bean (Green Moong)",
            "image": MungBean,
            "description": "Mung beans, also known as green gram or green moong, are small, green legumes with a slightly sweet flavor and tender texture.",
            "grades_quality": "Mung beans are graded similarly to other legumes, based on factors like size, color, and uniformity.",
            "varieties": "Varieties include whole mung beans and split mung beans, each with its unique culinary uses.",
            "health_benefits": "Mung beans are rich in protein, fiber, vitamins, and minerals. They may aid in weight management, support digestive health, and regulate blood sugar levels.",
            "storage_tips_shelf_life": "Store mung beans in an airtight container in a cool, dry place. Properly stored, they can last for up to one to two years."
        },
        {
            "name": "Yellow Pigeon Peas (Tur Dal)",
            "image": YellowPigeonPeas,
            "description": "Pigeon peas, also known as tur dal or toor dal, are small, round legumes with a mild, nutty flavor and a yellow color.",
            "grades_quality": "Tur dal is graded based on factors such as size, color, and uniformity. Higher quality tur dal will be uniform in size and free from impurities.",
            "varieties": "Varieties include regular tur dal and organic tur dal, which is grown without synthetic pesticides or fertilizers.",
            "health_benefits": "Tur dal is rich in protein, fiber, vitamins, and minerals. It may aid in weight management, support heart health, and regulate blood sugar levels.",
            "storage_tips_shelf_life": "Store tur dal in an airtight container in a cool, dry place. Properly stored, it can last for up to one to two years."
        },
        {
            "name": "Green Peas",
            "image": GreenPeas,
            "description": "Green peas are small, round legumes with a sweet, grassy flavor and vibrant green color. They are commonly used in both fresh and dried forms.",
            "grades_quality": "Green peas are graded based on factors such as size, color, and tenderness. Higher quality peas will be vibrant green, plump, and tender.",
            "varieties": "Varieties include fresh green peas, frozen green peas, and dried green peas, each with its unique culinary uses.",
            "health_benefits": "Green peas are rich in protein, fiber, vitamins, and minerals. They may aid in weight management, support digestive health, and improve heart health.",
            "storage_tips_shelf_life": "Store fresh green peas in the refrigerator, where they can last for up to three to five days. Frozen green peas should be stored in the freezer, lasting for up to six months to one year. Dried green peas should be stored in an airtight container in a cool, dry place, lasting for up to one to two years."
        },
        {
            "name": "Black Gram",
            "image": BlackGrams,
            "description": "Black gram, also known as urad dal or black lentil, is a small, black legume with a creamy white interior. It has a mild, earthy flavor and is commonly used in Indian cuisine.",
            "grades_quality": "Black gram is graded based on factors such as size, color, and uniformity. Higher quality black gram will be uniform in size and free from impurities.",
            "varieties": "Varieties include whole black gram and split black gram, each with its unique culinary uses.",
            "health_benefits": "Black gram is rich in protein, fiber, vitamins, and minerals. It may aid in weight management, support bone health, and regulate blood sugar levels.",
            "storage_tips_shelf_life": "Store black gram in an airtight container in a cool, dry place. Properly stored, it can last for up to one to two years."
        },
        {
            "name": "Urad dal",
            "image": UradDal,
            "description": "Urad dal, also known as black gram dal or white lentil, is a split and hulled version of black gram. It has a creamy white color and a mild, nutty flavor.",
            "grades_quality": "Urad dal is graded similarly to other lentils, based on factors like size, color, and uniformity.",
            "varieties": "Varieties include whole urad dal and split urad dal, each with its unique culinary uses.",
            "health_benefits": "Urad dal is rich in protein, fiber, vitamins, and minerals. It may aid in weight management, support heart health, and improve bone health.",
            "storage_tips_shelf_life": "Store urad dal in an airtight container in a cool, dry place. Properly stored, it can last for up to one to two years."
        },
        {
            "name": "Yellow Moong dal",
            "image": YellowMoongDal,
            "description": "Moong dal, also known as mung dal or split green gram, is made by splitting whole green mung beans. It has a mild, nutty flavor and cooks relatively quickly.",
            "grades_quality": "Moong dal is graded similarly to other lentils, based on factors such as size, color, and uniformity.",
            "varieties": "Varieties include whole moong dal and split moong dal, each with its unique culinary uses.",
            "health_benefits": "Moong dal is rich in protein, fiber, vitamins, and minerals. It may aid in weight management, support digestive health, and regulate blood sugar levels.",
            "storage_tips_shelf_life": "Store moong dal in an airtight container in a cool, dry place. Properly stored, it can last for up to one to two years."
        },
        {
            "name": "Soybeans",
            "image": Soybeans,
            "description": "Soybeans are small, oval-shaped legumes with a slightly nutty flavor and a variety of culinary uses. They are commonly used to make soy products such as tofu, soy milk, and tempeh.",
            "grades_quality": "Soybeans are graded based on factors such as size, color, and uniformity. Higher quality soybeans will be uniform in size and free from impurities.",
            "varieties": "Varieties include whole soybeans and processed soy products like soy flour, soy protein, and soy oil.",
            "health_benefits": "Soybeans are rich in protein, fiber, vitamins, and minerals. They are a complete source of plant-based protein and may aid in weight management, support heart health, and reduce the risk of certain diseases.",
            "storage_tips_shelf_life": "Store soybeans in an airtight container in a cool, dry place. Properly stored, they can last for up to one to two years."
        }
    ]
}


export const finalFlourAndAttaData = {
    "type": "Flour/Atta",
    "items": [
        {
            "name": "Wheat Flour",
            "image": WheatFlour,
            "description": "Wheat flour is a powder made from grinding wheat grains. It is commonly used in baking bread, pastries, and other baked goods.",
            "grades_quality": "Wheat flour quality varies based on factors such as protein content, gluten strength, and milling process. Higher quality flour will have a fine texture, consistent color, and strong gluten formation.",
            "varieties": "Varieties include all-purpose flour, bread flour, whole wheat flour, and pastry flour, each with different protein content and gluten strength.",
            "health_benefits": "Wheat flour is a good source of complex carbohydrates, fiber, protein, vitamins, and minerals. It provides energy, supports digestive health, and may help regulate blood sugar levels when consumed in whole grain form.",
            "storage_tips_shelf_life": "Store wheat flour in an airtight container in a cool, dry place, away from heat and moisture. Properly stored, it can last for up to six to twelve months."
        },
        {
            "name": "Gram Flour (Besan)",
            "image": GramFlour,
            "description": "Gram flour, also known as besan, is a flour made from ground chickpeas. It has a slightly nutty flavor and is commonly used in Indian and Middle Eastern cuisines.",
            "grades_quality": "Gram flour quality depends on factors such as texture, color, and purity. Higher quality flour will have a fine texture, uniform color, and no added ingredients.",
            "varieties": "Varieties include regular gram flour and roasted gram flour, which has a slightly different flavor due to the roasting process.",
            "health_benefits": "Gram flour is gluten-free and rich in protein, fiber, vitamins, and minerals. It aids in digestion, supports heart health, and may help regulate blood sugar levels.",
            "storage_tips_shelf_life": "Store gram flour in an airtight container in a cool, dry place. Properly stored, it can last for up to six to twelve months."
        },
        {
            "name": "Maize (Corn) Flour",
            "image": CornFlour,
            "description": "Maize flour, also known as cornmeal, is a coarse flour made from ground dried corn kernels. It is commonly used in making cornbread, tortillas, and other corn-based dishes.",
            "grades_quality": "Maize flour quality depends on factors such as texture, color, and purity. Higher quality flour will have a fine texture, uniform color, and no added ingredients.",
            "varieties": "Varieties include regular maize flour and stone-ground maize flour, which retains more of the corn's natural flavor and texture.",
            "health_benefits": "Maize flour is gluten-free and rich in carbohydrates, fiber, vitamins, and minerals. It supports digestive health, provides energy, and may help lower cholesterol levels.",
            "storage_tips_shelf_life": "Store maize flour in an airtight container in a cool, dry place. Properly stored, it can last for up to six to twelve months."
        },
        {
            "name": "Soya Flour",
            "image": SoyaFlour,
            "description": "Soya flour is a flour made from ground soybeans. It has a nutty flavor and is commonly used as a protein-rich ingredient in baked goods and other foods.",
            "grades_quality": "Soya flour quality depends on factors such as texture, color, and protein content. Higher quality flour will have a fine texture, uniform color, and high protein content.",
            "varieties": "Varieties include regular soya flour and full-fat soya flour, which contains more of the soybean's natural oils.",
            "health_benefits": "Soya flour is rich in protein, fiber, vitamins, and minerals. It supports muscle growth, bone health, and heart health. It's also beneficial for vegetarians and vegans as a plant-based protein source.",
            "storage_tips_shelf_life": "Store soya flour in an airtight container in a cool, dry place. Properly stored, it can last for up to six to twelve months."
        },
        {
            "name": "Bread Flour",
            "image": BreadFlour,
            "description": "Bread flour is a high-protein flour made from hard wheat varieties. It has a higher gluten content compared to all-purpose flour, making it ideal for baking bread and other yeast-risen baked goods.",
            "grades_quality": "Bread flour quality depends on factors such as protein content, gluten strength, and milling process. Higher quality flour will have a fine texture, consistent color, and strong gluten formation.",
            "varieties": "Varieties include regular bread flour and organic bread flour, which is produced from organically grown wheat without synthetic additives or pesticides.",
            "health_benefits": "Bread flour is a good source of protein, fiber, vitamins, and minerals. It supports muscle growth, energy production, and overall health when consumed as part of a balanced diet.",
            "storage_tips_shelf_life": "Store bread flour in an airtight container in a cool, dry place. Properly stored, it can last for up to six to twelve months."
        },
        {
            "name": "Kuttu Flour",
            "image": KuttuFlour,
            "description": "Kuttu flour, also known as buckwheat flour, is a gluten-free flour made from ground buckwheat groats. It has a nutty flavor and is commonly used in gluten-free baking and cooking.",
            "grades_quality": "Kuttu flour quality depends on factors such as texture, color, and purity. Higher quality flour will have a fine texture, uniform color, and no added ingredients.",
            "varieties": "Varieties include regular kuttu flour and organic kuttu flour, which is produced from organically grown buckwheat without synthetic additives or pesticides.",
            "health_benefits": "Kuttu flour is gluten-free and rich in protein, fiber, vitamins, and minerals. It supports heart health, regulates blood sugar levels, and aids in digestion.",
            "storage_tips_shelf_life": "Store kuttu flour in an airtight container in a cool, dry place. Properly stored, it can last for up to six to twelve months."
        },
        {
            "name": "Jau ka Flour (Barley flour)",
            "image": JauKaFlour,
            "description": "Jau ka flour, also known as barley flour, is a flour made from ground barley grains. It has a slightly sweet, nutty flavor and is commonly used in baking and cooking.",
            "grades_quality": "Jau ka flour quality depends on factors such as texture, color, and purity. Higher quality flour will have a fine texture, uniform color, and no added ingredients.",
            "varieties": "Varieties include regular jau ka flour and organic jau ka flour, which is produced from organically grown barley without synthetic additives or pesticides.",
            "health_benefits": "Jau ka flour is rich in fiber, vitamins, and minerals. It supports digestive health, regulates blood sugar levels, and may help lower cholesterol levels.",
            "storage_tips_shelf_life": "Store jau ka flour in an airtight container in a cool, dry place. Properly stored, it can last for up to six to twelve months."
        }
    ]
}


export const finalFruitsAndVegetablesData = {
    "type": "Fruits/Vegetables",
    "items": [
        {
            "name": "Fresh Mangoes",
            "image": FreshMangoes,
            "description": "Fresh mangoes are sweet and juicy tropical fruits with a smooth, fragrant flesh and a large, flat pit in the center.",
            "grades_quality": "Mangoes are graded based on factors such as size, color, aroma, and blemishes. Higher quality mangoes will be large, firm, brightly colored, and have a sweet aroma without any visible bruises or blemishes.",
            "varieties": "Varieties of mangoes include Alphonso, Ataulfo (Honey), Tommy Atkins, Kent, and Haden, each with its unique flavor, texture, and appearance.",
            "health_benefits": "Mangoes are rich in vitamins A, C, and E, as well as fiber, antioxidants, and other nutrients. They may help boost immunity, promote eye health, aid digestion, and improve skin health.",
            "storage_tips_shelf_life": "Store ripe mangoes at room temperature until they are soft and yield slightly to gentle pressure. Once ripe, store them in the refrigerator to extend their shelf life for up to five days."
        },
        {
            "name": "Fresh Potatoes",
            "image": FreshPotato,
            "description": "Fresh potatoes are starchy tubers with a thin, edible skin and a soft, creamy flesh. They come in various colors, including white, yellow, and red.",
            "grades_quality": "Potatoes are graded based on factors such as size, shape, skin texture, and absence of blemishes or sprouts. Higher quality potatoes will be firm, smooth-skinned, and free from cuts, bruises, or green spots.",
            "varieties": "Varieties of potatoes include russet, Yukon Gold, red, fingerling, and purple, each with its unique flavor, texture, and culinary uses.",
            "health_benefits": "Potatoes are rich in carbohydrates, fiber, vitamins (such as vitamin C and B vitamins), and minerals (such as potassium and magnesium). They may help regulate blood sugar levels, support heart health, aid digestion, and boost energy levels.",
            "storage_tips_shelf_life": "Store potatoes in a cool, dark place with good ventilation, such as a pantry or cellar. Avoid storing them in the refrigerator, as the cold temperature can convert the starches into sugars and affect their flavor and texture. Properly stored, potatoes can last for several weeks to several months, depending on the variety."
        }
    ]
}


export const finalProcessedFoodProductData = {
    "type": "Processed Food Products",
    "items": [
        {
            "name": "Alphonso Mango Pulp",
            "image": MangoPulp,
            "description": "Alphonso mango pulp is a smooth, thick puree made from the flesh of Alphonso mangoes. It has a vibrant yellow-orange color and a sweet, tropical flavor with floral undertones.",
            "grades_quality": "Alphonso mango pulp is graded based on factors such as color, consistency, sweetness, and absence of fibers or impurities. Higher quality pulp will have a bright, consistent color, smooth texture, and intense mango flavor.",
            "varieties": "Varieties of Alphonso mango pulp include natural Alphonso mango pulp and sweetened Alphonso mango pulp, each with its level of sweetness and sugar content.",
            "health_benefits": "Alphonso mango pulp is rich in vitamins (such as vitamin C, vitamin A, and vitamin E), antioxidants, and dietary fiber. It provides a concentrated source of nutrients and may help boost immunity, support eye health, aid digestion, and improve skin health.",
            "storage_tips_shelf_life": "Store Alphonso mango pulp in an airtight container or resealable pouch in the refrigerator. Once opened, consume it within a few days to maintain freshness. Unopened cans or pouches can be stored in the refrigerator for up to six months."
        },
        {
            "name": "Kesar Mango Pulp",
            "image": MangoPulp,
            "description": "Kesar mango pulp is a smooth, thick puree made from the flesh of Kesar mangoes. It has a bright yellow color and a sweet, aromatic flavor with hints of saffron.",
            "grades_quality": "Kesar mango pulp is graded similarly to Alphonso mango pulp, based on factors such as color, consistency, sweetness, and absence of fibers or impurities.",
            "varieties": "Varieties of Kesar mango pulp include natural Kesar mango pulp and sweetened Kesar mango pulp, each with its level of sweetness and sugar content.",
            "health_benefits": "Kesar mango pulp is rich in vitamins (such as vitamin C, vitamin A, and vitamin E), antioxidants, and dietary fiber. It provides a concentrated source of nutrients and may help boost immunity, support eye health, aid digestion, and improve skin health.",
            "storage_tips_shelf_life": "Store Kesar mango pulp in an airtight container or resealable pouch in the refrigerator. Once opened, consume it within a few days to maintain freshness. Unopened cans or pouches can be stored in the refrigerator for up to six months."
        },
        {
            "name": "Fruit Pulps",
            "image": FruitPulp,
            "description": "Fruit pulps are smooth, thick purees made from the flesh of various fruits, including mangoes, guavas, papayas, and more. They have vibrant colors and intense flavors characteristic of the respective fruits.",
            "grades_quality": "Fruit pulps are graded based on factors such as color, consistency, sweetness, and absence of fibers or impurities. Higher quality pulps will have vibrant colors, smooth textures, and intense fruit flavors.",
            "varieties": "Varieties of fruit pulps include mango pulp, guava pulp, papaya pulp, pineapple pulp, and more, each with its unique flavor and nutritional profile.",
            "health_benefits": "Fruit pulps are rich in vitamins, minerals, antioxidants, and dietary fiber, depending on the fruit used. They provide concentrated sources of nutrients and may help support overall health, boost immunity, aid digestion, and promote skin health.",
            "storage_tips_shelf_life": "Store fruit pulps in airtight containers or resealable pouches in the refrigerator. Once opened, consume them within a few days to maintain freshness. Unopened cans or pouches can be stored in the refrigerator for up to six months."
        }
    ]
};
