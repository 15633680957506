import Spices from "../assets/final_asset/our-product/1. Spices Whole & Grinded.jpeg";
import Pulses from "../assets/final_asset/our-product/2. Pulses.jpeg";
import Flour from "../assets/final_asset/our-product/3. Flour_Atta.jpeg";
import Fruits from "../assets/final_asset/our-product/4. Fresh Fruits&Vegetables.jpeg";
import ProcessFood from "../assets/final_asset/our-product/5. Processed Food Products.jpeg";

import CuminSeed from "../assets/Cumin-Spice.webp"

export const ourProductData = [
  {
    id: 1,
    image: Spices,
    productName: "SPICES (Whole & Grounded)",
    route: '/product/spices-and-masala',
    desc: `Spices are natural substances derived from plants, seeds, bark, roots, or fruits, used to flavor food. They enhance taste, aroma, and color, making dishes more delicious and diverse.`,
  },
  {
    id: 2,
    image: Pulses,
    productName: "Pulses",
    route: '/product/pulses',
    desc: `Pulses are edible seeds from the legume family, used as food. They are typically high in protein, fiber, and essential nutrients. Common examples of pulses include lentils, beans, chickpeas, and peas. Pulses are a staple in many diets worldwide due to their nutritional benefits and versatility in cooking.`,
  },
  {
    id: 3,
    image: Flour,
    productName: "Flour/Atta",
    route: '/product/flour-and-atta',
    desc: `Flour, also known as atta (especially in South Asia), is a powder made by grinding raw grains, roots, beans, nuts, or seeds. It is a fundamental ingredient in many foods and is used primarily in baking and cooking. Flour is versatile and can be used to make various food items such as bread, cakes, pastries, noodles, and thickening agents for sauces and soups. It provides structure, texture, and flavor to a wide range of dishes, making it an essential staple in cuisines around the world.`,
  },
  {
    id: 4,
    image: Fruits,
    productName: "Fruits/Vegetables",
    route: '/product/fruits-and-vegetables',
    desc: `Fresh fruits and vegetables refer to produce that is harvested and consumed in its natural state, without undergoing significant processing or preservation methods. These are fruits and vegetables that have not been canned, frozen, dried, or otherwise preserved for an extended period. They are typically sold in their raw, natural form and are valued for their nutritional content, flavor, and freshness. Eating fresh fruits and vegetables is often encouraged for their abundance of vitamins, minerals, fiber, and antioxidants, which are beneficial for overall health and well-being.`,
  },
  {
    id: 5,
    image: ProcessFood,
    productName: "Processed Food Products",
    route: '/product/processed-food-product',
    desc: `Processed food products are food items that have undergone various methods of preparation, preservation, or alteration before being consumed. These methods can include cooking, canning, freezing, drying, fermenting, or adding preservatives, flavorings, or other additives. Processed food products can range from minimally processed items like washed and chopped vegetables to highly processed foods such as ready-to-eat meals, snacks, and packaged goods like canned soups, frozen dinners, and snack bars. While processing can sometimes enhance convenience, shelf life, and taste, it may also result in changes to the nutritional content of the food, such as the addition of salt, sugar, or unhealthy fats.`,
  },
];

export const productData = [
  {
    id: 1,
    productName: "Cumin Seeds",
    productData: `
    <h2>Cumin Seeds - Nature's Flavour Enhancer</h2>
    
    <img src="${CuminSeed}" class="product-page-image" alt="product-image"/>
        <h3>Overview:</h3>
        <p>Cumin seeds, derived from the <em>Cuminum cyminum</em> plant, are a versatile spice that adds a warm, earthy flavour to a variety of dishes. These small, elongated seeds are a staple in many cuisines around the world, prized for their unique taste and aroma.</p>
        
        <h3>Flavour Profile:</h3>
        <p>Cumin seeds have a distinctive flavour that is warm, slightly nutty, and earthy, with a hint of citrus. When toasted or ground, they release a rich, aromatic fragrance that enhances the overall taste of dishes.</p>
        
        <h3>Culinary Uses:</h3>
        <ul>
          <li>Whole cumin seeds are often used in tempering, adding a flavourful crunch to dishes.</li>
          <li>Ground cumin seeds are a key ingredient in spice blends, curries, soups, and stews, adding depth and complexity to the flavour profile.</li>
        </ul>
        
        <h3>Health Benefits:</h3>
        <p>Cumin seeds are not just flavourful but also offer several health benefits. They are rich in antioxidants and may aid digestion, reduce inflammation, and promote weight loss.</p>
        
        <h3>Quality Assurance:</h3>
        <p>Our cumin seeds are sourced from trusted suppliers and undergo rigorous quality checks to ensure they meet our standards for freshness, flavour, and purity.</p>
        
        <h3>Storage and Shelf Life:</h3>
        <p>To maintain their flavour and aroma, store cumin seeds in a cool, dry place away from direct sunlight. Ground cumin seeds should be used within six months for optimal flavour and its shelf life is more than one year.</p>
        
        <h3>Conclusion:</h3>
        <p>Add a touch of warmth and flavour to your dishes with our premium-quality cumin seeds. Whether you're cooking up a curry or seasoning roasted vegetables, our cumin seeds are sure to elevate your culinary creations.</p>
        
        <h4>India Cumin Seed Grades and Detailed Specifications</h4>
        DSI Heritage Spices is a reputable exporter and supplier of cumin seeds from India. We consistently export high-quality cumin seeds, including European Quality Cumin Seed CRE-101 with 99.5% purity, Gulf Quality Cumin Seed CRE-104 with 99% purity, and Singapore Quality Cumin Seed CRE-106 with 99% purity, to destinations worldwide. Gujarat and Rajasthan are the primary cumin seed-producing states in India, contributing to 90% of the total production. Compared to Turkey, Syria, or Iran, India boasts a significantly higher cumin seed production. This abundance ensures that Indian cumin seed stocks remain available year-round, unlike stocks from other origins like Iran and Syria, which are often depleted quickly.</p>`,
    specificationData: [
      {
        Title: "European Quality Cumin Seed",
        details: {
          Quality: ["CRE 101", "CRE 102", "CRE 103"],
          Purity: ["99.5%", "99%", "98%"],
          Process: ["Sortex", "Machine Clean"],
        },
        tableData: [
          {
            Title:
              "Specifications of European Quality CRE 101 - 99.5% Cumin Seed",
            Specifications: {
              Quality: "European - CRE 101",
              Purity: "99.5 %",
              Process: "Sortex",
              "Volatile Oil Content": "2.5 % - 4.5 %",
              Admixture: "0.5 %",
              "Moisture ± 2 %": "7 %",
              Origin: "India",
            },
          },
          {
            Title:
              "Specifications of European Quality CRE 102 - 99% Cumin Seed",
            Specifications: {
              Quality: "Europian - CRE 102",
              Purity: "99 %",
              Process: "Machine Clean",
              "Volatile Oil Content": "2.5 % - 4.5 %",
              Admixture: "1 %",
              "Moisture ± 2 %": "7 %",
              Origin: "India",
            },
          },
          {
            Title:
              "Specifications of European Quality CRE 103 - 98% Cumin Seed",
            Specifications: {
              Quality: "Europian - CRE 103",
              Purity: "98 %",
              Process: "Machine Clean",
              "Volatile Oil Content": "2.5 % - 4.5 %",
              Admixture: "2 %",
              "Moisture ± 2 %": "7 %",
              Origin: "India",
            },
          },
        ],
      },
      {
        Title: "Gulf Quality Cumin Seed",
        details: {
          Quality: ["CRE 104", "CRE 105"],
          Purity: ["99%", "98%"],
          Process: ["Machine Clean"],
        },
        tableData: [
          {
            Title: "Specifications of Gulf Quality CRE 104 - 99% Cumin Seed",
            Specifications: {
              Quality: "Gulf - CRE 104",
              Purity: "99 %",
              Process: "Machine Clean",
              "Volatile Oil Content": "2.5 % - 4.5 %",
              Admixture: "1 %",
              "Moisture ± 2 %": "7 %",
              Origin: "India",
            },
          },
          {
            Title: "Specifications of Gulf Quality CRE 105 - 98% Cumin Seed",
            Specifications: {
              Quality: "Gulf - CRE 105",
              Purity: "98 %",
              Process: "Machine Clean",
              "Volatile Oil Content": "2.5 % - 4.5 %",
              Admixture: "2 %",
              "Moisture ± 2 %": "7 %",
              Origin: "India",
            },
          },
        ],
      },
      {
        Title: "Singapore Quality Cumin Seed",
        details: {
          Quality: ["CRE 106", "CRE 107"],
          Purity: ["99%", "98%"],
          Process: ["Machine Clean"],
        },
        tableData: [
          {
            Title:
              "Specifications of Singapore Quality CRE 106 - 99% Cumin Seed",
            Specifications: {
              Quality: "Singapore - CRE 106",
              Purity: "99 %",
              Process: "Machine Clean",
              "Volatile Oil Content": "2.5 % - 4.5 %",
              Admixture: "1 %",
              "Moisture ± 2 %": "7 %",
              Origin: "India",
            },
          },
          {
            Title:
              "Specifications of Singapore Quality CRE 107 - 98% Cumin Seed",
            Specifications: {
              Quality: "Singapore - CRE 107",
              Purity: "98 %",
              Process: "Machine Clean",
              "Volatile Oil Content": "2.5 % - 4.5 %",
              Admixture: "2 %",
              "Moisture ± 2 %": "7 %",
              Origin: "India",
            },
          },
        ],
      },
    ],
  },
  {
    id: 2,
    productName: "Turmeric Powder",
    productData: `
        <h2>Turmeric Powder</h2>
        <img src="${CuminSeed}" class="product-page-image" alt="product-image"/>
        <h3>Overview</h3>
        <p>Turmeric powder, derived from the rhizomes of the <em>Curcuma longa</em> plant, is a vibrant yellow spice commonly used in Indian cuisine and traditional medicine. Known for its earthy flavour and bright colour, turmeric has been a staple in cooking and healing for centuries.</p>
        
        <h3>Flavour Profile</h3>
        <p>Turmeric powder has a warm, bitter taste with a peppery aroma. It adds depth and complexity to dishes, often used in curries, soups, and rice dishes. The flavour is distinctive and can be overpowering if used in excess.</p>
        
        <h3>Culinary Uses</h3>
        <ul>
          <li>Turmeric powder is a versatile spice used in a variety of dishes. It is a key ingredient in curry powders, giving them their characteristic yellow colour. It is also used to season meats, vegetables, and rice dishes. Additionally, turmeric is used to add colour and flavour to pickles, relishes, and mustard.</li>
        </ul>
        
        <h3>Health Benefits</h3>
        <p>Turmeric is renowned for its potential health benefits. It contains curcumin, a compound with antioxidant and anti-inflammatory properties. Turmeric is believed to support digestion, promote heart health, and help reduce inflammation in the body. It is also used in traditional medicine for its antibacterial and antiviral properties.</p>
        
        <h3>Quality Assurance</h3>
        <p>When purchasing turmeric powder, it's important to ensure it is of high quality. Look for brands that source their turmeric from reputable suppliers and adhere to strict quality control standards. The powder should have a bright, uniform colour and a strong, aromatic smell.</p>
        
        <h3>Storage and Shelf Life</h3>
        <p>To maintain the freshness and potency of turmeric powder, store it in an airtight container in a cool, dark place, away from moisture and sunlight. Properly stored, turmeric powder can retain its flavour and aroma for up to two years.</p>
        
        <h3>Conclusion</h3>
        <p>Turmeric powder is a versatile spice with a rich history and a range of culinary and health benefits. Its distinctive flavour and vibrant colour make it a popular choice in kitchens around the world. Whether used in curries, soups, or as a natural remedy, turmeric adds a unique and delicious element to any dish.</p>
        
        India Turmeric Grades and Detailed Specifications<br>
        DSI Heritage Spices is a reputable exporter and supplier of cumin seeds from India. We consistently export high-quality cumin seeds, including European Quality Cumin Seed CRE-101 with 99.5% purity, Gulf Quality Cumin Seed CRE-104 with 99% purity, and Singapore Quality Cumin Seed CRE-106 with 99% purity, to destinations worldwide. Gujarat and Rajasthan are the primary cumin seed-producing states in India, contributing to 90% of the total production. Compared to Turkey, Syria, or Iran, India boasts a significantly higher cumin seed production. This abundance ensures that Indian cumin seed stocks remain available year-round, unlike stocks from other origins like Iran and Syria, which are often depleted quickly.</p>`,
    specificationData: [
      {
        Title: "Alleppey Finger From Kerala",
        details: {
          Quality: ["Alleppey"],
          "Crop of Indian State": ["Kerala"],
        },
        Specifications: {
          Quality: "Alleppey",
          Appearance: "Whole Turmeric Finger",
          Origin: "Kerala – India",
          Flexibility: "Should be Hard to Touch",
          "Broken Pieces": "5% Maximum",
          "Foreign Matter": "1% Maximum",
          Defective: "3% Maximum",
          Bulbs: "4% Maximum",
        },
      },
    ],
  },
  {
    id: 3,
    productName: "Chilli Powder",
    productData: `
        <h2>Chilli powder</h2>
        <img src="${CuminSeed}" class="product-page-image" alt="product-image"/>
        <h4>Overview</h4>
        <p>Chilli powder is a popular spice made from dried, ground chili peppers. It is widely used in cooking to add heat and flavour to dishes. Chilli powder can vary in heat level depending on the type of chili peppers used and how it is processed.</p>
        
        <h4>Flavour Profile</h4>
        <p>Chilli powder has a spicy, pungent flavour with varying degrees of heat. It can range from mild to extremely hot, depending on the type and amount of chili peppers used.</p>
        
        <h4>Culinary Uses</h4>
        <p>Chilli powder is a versatile spice used in a variety of dishes. It is commonly used in Indian, Mexican, and Thai cuisines to add heat and flavour to curries, sauces, soups, and marinades. It can also be used to season meats, vegetables, and snacks like popcorn and nuts.</p>
        
        <h4>Health Benefits</h4>
        <p>Chilli powder contains capsaicin, which is known to have several health benefits. It can help boost metabolism, reduce inflammation, and improve digestion. Capsaicin is also believed to have anti-cancer properties and may help lower blood pressure and cholesterol levels.</p>
        
        <h4>Quality Assurance</h4>
        <p>When purchasing chilli powder, it is important to look for high-quality, pure products. Check the ingredients list to ensure that there are no additives or preservatives. It is also a good idea to buy from reputable brands or suppliers to ensure the quality and safety of the product.</p>
        
        <h4>Storage and Shelf Life</h4>
        <p>Chilli powder should be stored in a cool, dry place away from direct sunlight. It should be kept in an airtight container to maintain its freshness and flavour. Properly stored, chilli powder can last for up to two years.</p>
        
        <h4>Conclusion</h4>
        <p>Chilli powder is a versatile spice that adds heat and flavour to a variety of dishes. It has several health benefits and can be a valuable addition to your spice cabinet. When buying chilli powder, make sure to choose a high-quality product and store it properly to maintain its freshness and flavour.</p>
        `,
    specificationData: [],
  },
];
