import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./components/HomePage";
import Footer from "./components/Footer";
import { privacyPolicyData } from "./data/PrivacyPolicyData";
import { termsAndConditionData } from "./data/TermsAndConditionData";
import ContentPage from "./components/ContentPage";
import Certificatiions from "./components/Certificatiions";
import Products from "./components/Products";
import SpicesAndMasala from "./components/products/SpicesAndMasala";
import Pulses from "./components/products/Pulses";
import FlourAndAtta from "./components/products/FlourAndAtta";
import ProcessedFoodsProduct from "./components/products/ProcessedFoodsProduct";
import FruitsAndVegetables from "./components/products/FruitsAndVegetables";

const App = () => {
  let location = useLocation();
  const contactUsRef = useRef(null);
  const footerRef = useRef(null);
  const [routeName, setRouteName] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    setRouteName(location.pathname);
  }, [location]);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      // Check if the user has scrolled past a certain point (e.g., 100 pixels)
      if (window.scrollY > 40) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Add scroll event listener when component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToBottom = () => {
    contactUsRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  };

  return (
    <>
      <Header
        routeName={routeName}
        contactUsRef={footerRef}
        isSticky={isSticky}
        screenWidth={screenWidth}
        scrollToBottom={scrollToBottom}
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <HomePage contactUsRef={contactUsRef} screenWidth={screenWidth} />
          }
        />
        <Route
          exact
          path="/privacy-policy"
          element={<ContentPage pageContent={privacyPolicyData} />}
        />
        <Route
          exact
          path="/terms-and-condition"
          element={<ContentPage pageContent={termsAndConditionData} />}
        />
        <Route exact path="/certifications" element={<Certificatiions />} />
        <Route exact path="products/:productName" element={<ContentPage />} />
        <Route path="/product">
          <Route path="spices-and-masala/" element={<SpicesAndMasala screenWidth={screenWidth} />} />
          <Route path="spices-and-masala/:itemNumber" element={<Products />} />
          <Route path="pulses" element={<Pulses />} />
          <Route path="pulses/:itemNumber" element={<Products />} />
          <Route path="flour-and-atta" element={<FlourAndAtta />} />
          <Route path="flour-and-atta/:itemNumber" element={<Products />} />
          <Route path="fruits-and-vegetables" element={<FruitsAndVegetables />} />
          <Route path="fruits-and-vegetables/:itemNumber" element={<Products />} />
          <Route path="processed-food-product" element={<ProcessedFoodsProduct />} />
          <Route path="processed-food-product/:itemNumber" element={<Products />} />
        </Route>
      </Routes>
      <Footer />
      <div ref={footerRef}></div>
    </>
  );
};

export default App;
