import React from "react";

import Apeda from "../assets/final_asset/member/Apeda Agri Exchange.png"
import Duns from "../assets/final_asset/member/DUNS Number.png"
import Fieo from "../assets/final_asset/member/FIEO - Global Linkers.png"
import Gem from "../assets/final_asset/member/GEM government market place.png"
import Msme from "../assets/final_asset/member/MSMEmart_logo.png"
import Ondc from "../assets/final_asset/member/ONDC.png"
import Spice from "../assets/final_asset/member/spice-xhange-logo.png"

const MemberSection = () => {
    const data = [
        {
            id: 1,
            name: "Apeda Agri Exchange",
            image: Apeda
        },
        {
            id: 2,
            name: "DUNS Number",
            image: Duns
        },
        {
            id: 3,
            name: "MSMEmart_logo",
            image: Fieo
        },
        {
            id: 4,
            name: "GEM government market place",
            image: Gem
        },
        {
            id: 5,
            name: "MSMEmart_logo",
            image: Msme
        },
        {
            id: 6,
            name: "ONDC",
            image: Ondc
        },
        // {
        //     id: 7,
        //     name: "spice-xhange-logo",
        //     image: Spice
        // },
    ];

    return (
        <div className="container-fluid px-0">
            <div className="fs-2 text-center text-white section-title">We are Member of</div>
            <div className="container">
                <div className="row justify-content-around">
                    {data.map((item) => {
                        return (
                            <div className="col-sm-12 col-md-4 text-center my-4 d-flex justify-content-center">
                                <div className="card cert-card">
                                    <img
                                        src={item.image}
                                        alt="..."
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default MemberSection;
