import React from 'react'
import { SliderComponent } from './SpicesAndMasala';
import { useNavigate } from 'react-router-dom';
import { finalPulsesData } from '../../data/FinalProductData';

const Pulses = () => {
    const navigate = useNavigate();
    return (
        <>
            <div style={{ backgroundColor: 'var(--primary-theme)' }}>
                <h2 className='text-center mb-2 pt-2 text-white'>{finalPulsesData.type}</h2>
                <p className='container text-white text-center'>
                    Pulses are edible seeds from the legume family, used as food. They are typically high in protein, fiber, and essential nutrients. Common examples of pulses include lentils, beans, chickpeas, and peas. Pulses are a staple in many diets worldwide due to their nutritional benefits and versatility in cooking.
                </p>
                <SliderComponent navigate={navigate} productData={finalPulsesData} />
            </div>
        </>
    )
}

export default Pulses